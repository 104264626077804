<section id="faq-section">
    <div class="container justify-content-center">
        <div class="row justify-content-center">
            <!--********** Section Heading **********-->
            <!-- If you change the md to another breakpoint, update the SCSS of the FAQ section heading container too. -->
            <div class="col-md-4">
                <div id="faq-section-heading-container">
                    <h3 class="additional-headline">FAQS</h3>
                    <h2>Häufige Fragen</h2>
                </div>
            </div>

            <!--********** Questions **********-->
            <div class="col-md-8">
                <!--********** Recording **********-->
                <ax-accordion title="Werden Fachwebinare aufgezeichnet?">
                    Wir haben in der Vergangenheit festgestellt, dass Teilnehmer sich weniger trauen Fragen zu stellen,
                    wenn das Webinar aufgezeichnet wird. Deshalb zeichnen wir nicht auf. Wir machen davon auch keine
                    Ausnahmen.
                </ax-accordion>

                <!--********** Cancellation Period **********-->
                <ax-accordion title="Stornierungsfrist">
                    Du kannst kostenfrei stornieren bis um 12 Uhr am Tag des Webinars. Schreib uns dazu eine Mail oder
                    rufe uns kurz an.
                </ax-accordion>

                <!--********** Link **********-->
                <ax-accordion title="Wann erhalte ich den Teilnahmelink?">
                    Du erhältst den Teilnahmelink wenige Stunden vor dem Webinar an die Mailadresse, mit der du dich zum
                    Webinar angemeldet hast.
                </ax-accordion>

                <!--********** Invoice **********-->
                <ax-accordion title="Wann erhalte ich die Rechnung?">
                    Die Rechnung erhältst du meist am Tag vor dem Webinar. Wenn du dich mit der gleichen Mailadresse
                    registriert hast, die auch deinem autoiXpert-Nutzernamen entspricht, können wir dich zuordnen und
                    die Rechnung ggf. komfortabel per SEPA einziehen. Ob du selbst überweisen musst oder wir einziehen
                    können, steht immer auch explizit auf der Rechnung.
                </ax-accordion>

                <!--********** Certificate of Attendance **********-->
                <ax-accordion title="Wann erhalte ich Teilnahmezertifikat und Präsentation?">
                    Beides erhältst du meist 1-2 Tage nach dem Webinar.
                </ax-accordion>

                <!--********** Payment Methods **********-->
                <ax-accordion title="Wie zahle ich?">
                    Ganz bequem auf Rechnung. Du brauchst keine Kreditkarte.
                </ax-accordion>
            </div>
        </div>
    </div>
</section>
