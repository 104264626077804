export const ORGANIZATIONAL_TALENT_STRUCTURED_DATA = {
    jobPosting: {
        '@context': 'http://schema.org',
        '@type': 'JobPosting',
        title: 'Buchhaltung',
        description:
            'Bist du smart und hast schon mehrere Jahre in der Orga eines Unternehmens gearbeitet? Bring deine Karriere mit autoiXpert auf die nächste Stufe und arbeite in einem jungen dynamischen Team.',
        datePosted: '2023-08-01',
        validThrough: '2024-03-31',
        experienceRequirements: {
            '@type': 'OccupationalExperienceRequirements',
            monthsOfExperience: '24',
        },
        applicantLocationRequirements: {
            '@type': 'Country',
            name: 'DE',
        },
        // TELECOMMUTE means that this is a home office job.
        jobLocationType: 'TELECOMMUTE',
        employmentType: 'PART_TIME',
        hiringOrganization: {
            '@type': 'Organization',
            name: 'autoiXpert GmbH & Co. KG',
            sameAs: 'https://www.autoixpert.de',
            logo: 'https://www.autoixpert.de/images/logo-autoixpert.svg',
        },
        baseSalary: {
            '@type': 'MonetaryAmount',
            currency: 'EUR',
            value: {
                '@type': 'QuantitativeValue',
                minValue: 4_800.0,
                maxValue: 9_600.0,
                unitText: 'YEAR',
            },
        },
        directApply: true,
    },
};
