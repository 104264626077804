<div>
    <section class="webinar-hero-section">
        <div id="keynote-hero-header-container-muenchen" class="webinar-hero-header-container">
            <h2 class="keynote-hero-heading">Produkt-Webinar</h2>
            <div class="webinar-hero-additional-info">
                {{ getWebinarDateLongForm() }}
                <br />
                {{ getWebinarTime() }} - {{ getWebinarTime(120) }} Uhr
                <div class="label">Die Teilnahme ist kostenfrei</div>
                <button
                    id="webinar-register-link-button"
                    class="newsletter-input primary-button"
                    (click)="scrollElemIntoView('#event-signup-section')">
                    Anmelden
                </button>
            </div>
        </div>
    </section>

    <!--********** Initially not shown. Only for displaying videos. **********-->
    <section id="hero-video-player-section">
        <svg id="close-video-player-icon" height="24" viewBox="0 0 24 24" width="24" (click)="closeVideoPlayer()">
            <path
                d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                fill="white" />
        </svg>
    </section>

    <!--============================================-->
    <!-- Agenda -->
    <!--============================================-->
    <section id="agenda-section">
        <div class="container text-center">
            <div id="agenda-intro-row" class="row justify-content-center">
                <div class="col-sm col-md-8">
                    <h2>autoiXpert kennenlernen - kompakt in 2 Stunden</h2>
                    <p>
                        Wir präsentieren autoiXpert anhand eines
                        <strong>vollständigen Haftpflichtschadens von A bis Z.</strong>
                    </p>
                    <!-- prettier-ignore -->
                    <p>
                        Im Webinar kannst du per Chat oder Mikrofon deine persönlichen Fragen stellen. Weitere Webinare
                        findest du auf
                        unserer
                        <a href="/">Startseite</a>.
                    </p>
                </div>
            </div>

            <!--********** Involved Parties **********-->
            <div class="row justify-content-center align-items-center agenda-item">
                <div class="agenda-time-container col-md-1">
                    {{ getWebinarTime() }}
                </div>
                <div class="col-md-1">
                    <div class="agenda-icon-container">
                        <img src="/assets/images/icons/assignment.png" alt="Live-Gutachten Fahrzeugdaten" />
                    </div>
                </div>
                <div class="col-md-6 agenda-item-details">
                    <div class="agenda-item-heading">Beteiligte & Fahrzeugdaten</div>
                    <div class="agenda-item-description">
                        In wenigen Minuten alle Daten aufnehmen und den Kunden digital die Abtretung & Co.
                        unterschreiben lassen.
                    </div>
                </div>
            </div>

            <!--********** Photos **********-->
            <div class="row justify-content-center align-items-center agenda-item">
                <div class="agenda-time-container col-md-1"></div>
                <div class="col-md-1">
                    <div class="agenda-icon-container">
                        <img src="/assets/images/icons/camera.png" alt="Einfache Fotobearbeitung im Gutachtenprozess" />
                    </div>
                </div>
                <div class="col-md-6 agenda-item-details">
                    <div class="agenda-item-heading">Einfache Fotobearbeitung</div>
                    <div class="agenda-item-description">
                        Fotobearbeitung eines Unfallschadens für Gutachten & Restwertbörse.
                    </div>
                </div>
            </div>

            <!--********** DAT calculation **********-->
            <div class="row justify-content-center align-items-center agenda-item">
                <div class="agenda-time-container col-md-1"></div>
                <div class="col-md-1">
                    <div class="agenda-icon-container">
                        <img src="/assets/images/icons/car.png" alt="DAT-Kalkulation" />
                    </div>
                </div>
                <div class="col-md-6 agenda-item-details">
                    <div class="agenda-item-heading">Kalkulation, Rest-, Markt- & Minderwert</div>
                    <div class="agenda-item-description">Kalkulation mit unserer Nahtlos-Schnittstelle zur DAT.</div>
                </div>
            </div>

            <!--********** Invoicing & Payment Reminders **********-->
            <div class="row justify-content-center align-items-center agenda-item">
                <div class="agenda-time-container col-md-1"></div>
                <div class="col-md-1">
                    <div class="agenda-icon-container">
                        <img src="/assets/images/icons/euro.png" alt="Rechnungs- & Mahnwesen" />
                    </div>
                </div>
                <div class="col-md-6 agenda-item-details">
                    <div class="agenda-item-heading">Honorar berechnen & Rechnungswesen</div>
                    <div class="agenda-item-description">
                        Berechne anhand aktueller Honorartabellen das richtige Honorar und behalte den Durchblick bei
                        Rechnungen und offenen Posten.
                    </div>
                </div>
            </div>

            <!--********** Agenda Divider **********-->
            <div id="agenda-lunch-divider-row" class="row justify-content-center">
                <div class="col-md-8">
                    <hr id="agenda-horizontal-divider" />
                </div>
            </div>

            <!--********** Questions & Answers **********-->
            <div class="row justify-content-center align-items-center agenda-item">
                <div class="agenda-time-container col-md-1">
                    {{ getWebinarTime(105) }}
                </div>
                <div class="col-md-1">
                    <div class="agenda-icon-container">
                        <img src="/assets/images/icons/question-answer.png" alt="Fragen & Antworten zu autoiXpert" />
                    </div>
                </div>
                <div class="col-md-6 agenda-item-details">
                    <div class="agenda-item-heading">Fragen & Antworten</div>
                    <div class="agenda-item-description">
                        Wir beantworten deine individuellen Fragen und machen bei Bedarf Abstecher in andere Funktionen.
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--============================================-->
    <!-- END Agenda -->
    <!--============================================-->

    <section id="event-signup-section">
        @if (!signupSuccessful && !eventOverbooked && !duplicate) {
            <form
                id="event-signup-container"
                class="container"
                (submit)="sendEventSignupForm(webinarDate); $event.preventDefault()">
                <div class="row">
                    <div id="event-signup-heading-container" class="col-md-12">
                        <h2 id="event-signup-heading">Sei mit dabei!</h2>
                        <p id="event-signup-release-note">
                            Produkt-Webinar
                            <span class="d-none d-md-inline">-</span>
                            <br class="d-md-none" />
                            {{ getWebinarDateLongFormWithWeekday() }} - {{ getWebinarTime() }} bis
                            {{ getWebinarTime(120) }} Uhr
                            <br />
                            <!--********** Reference to Video **********-->
                            Oder schau es dir jetzt schon
                            <a href="https://youtu.be/Ftp4H_3Ij88?si=kLc9TParSehyX4jl" target="_blank" rel="noopener">
                                als Video
                            </a>
                            an.
                        </p>
                    </div>
                </div>
                <div class="row justify-content-center event-signup-row">
                    <div class="col-md-4">
                        <input
                            type="text"
                            class="newsletter-input"
                            [(ngModel)]="organization"
                            [ngModelOptions]="{ standalone: true }"
                            placeholder="Name deines SV-Büros"
                            required />
                    </div>
                </div>
                <div class="row justify-content-center event-signup-row">
                    <div class="col-md-2">
                        <input
                            type="text"
                            class="newsletter-input"
                            [(ngModel)]="firstName"
                            [ngModelOptions]="{ standalone: true }"
                            placeholder="Vorname"
                            required />
                    </div>
                    <div class="col-md-2">
                        <input
                            type="text"
                            class="newsletter-input"
                            [(ngModel)]="lastName"
                            [ngModelOptions]="{ standalone: true }"
                            placeholder="Nachname"
                            required />
                    </div>
                </div>
                <div class="row justify-content-center event-signup-row">
                    <div class="col-md-4 email-with-error-message">
                        <input
                            #emailInput="ngModel"
                            [(ngModel)]="email"
                            [ngModelOptions]="{ standalone: true }"
                            email
                            [pattern]="emailValidationRegex"
                            type="email"
                            class="newsletter-input"
                            placeholder="E-Mail"
                            required />
                        <span class="error-message" *ngIf="emailInput.invalid && emailInput.touched">
                            E-Mail-Adresse nicht gültig.
                        </span>
                    </div>
                </div>
                <div class="row justify-content-center event-signup-row">
                    <div class="col-md-4">
                        <button
                            type="submit"
                            [class.disallowed]="disallowed()"
                            [title]="disallowed() ? 'Bitte fülle das Formular vollständig aus.' : ''"
                            id="newsletter-submit-button"
                            class="newsletter-input">
                            @if (signupPending) {
                                <img
                                    src="/assets/images/loading-indicator-white.svg"
                                    alt=""
                                    class="pending-indicator" />
                            }
                            Kostenfrei teilnehmen
                        </button>
                    </div>
                </div>
            </form>
        }
        @if (signupSuccessful || eventOverbooked || duplicate) {
            <div id="event-signup-response-container" class="container">
                @if (signupSuccessful) {
                    <div id="event-signup-successful">
                        <h2>Anmeldung erfolgreich</h2>
                        <p>Wir freuen uns, dich zu unserem Webinar begrüßen zu dürfen.</p>
                        <p>Du hast eine Termin-Bestätigung per E-Mail von uns bekommen.</p>
                    </div>
                }
                @if (eventOverbooked) {
                    <div id="event-overbooked-container">
                        <h2>Event bereits ausgebucht</h2>
                        <div>
                            Es tut uns leid, aber das Event ist bereits ausgebucht. Bitte suche dir einen anderen Termin
                            auf der
                            <a href="/">Startseite</a>
                            aus.
                        </div>
                    </div>
                }
                @if (duplicate) {
                    <div id="event-duplicate-email-container">
                        <h2>Bereits registriert</h2>
                        <div>Es hat sich bereits jemand mit diesem Namen und dieser E-Mailadresse registriert.</div>
                    </div>
                }
                <div>
                    <a href="javascript:void(0)" (click)="showSignupForm()">Weitere Person registrieren</a>
                    oder
                    <a href="/registrierung">autoiXpert 30 Tage kostenfrei testen</a>
                </div>
            </div>
        }
    </section>
</div>

<section id="feature-section">
    <div class="container">
        <div class="row">
            <div class="col-sm text-center mb-5">
                <h2>Warum autoiXpert?</h2>
            </div>
        </div>
        <div class="row">
            <!--============================================-->
            <!-- autoiXpert -->
            <!--============================================-->
            <div class="col-md-8">
                <div id="what-to-expect-autoixpert" class="what-to-expect-card">
                    <div class="what-to-expect-logo-container">
                        <img src="/assets/images/logo-autoixpert-invertiert-blaues-x.svg" alt="autoiXpert Logo" />
                    </div>

                    <div id="what-to-expect-features-and-screenshots-container">
                        <div id="what-to-expect-features">
                            <!--********** Faster Creation of Reports **********-->
                            <div class="what-to-expect-feature-container">
                                <div class="what-to-expect-feature-icon-container">
                                    <img
                                        src="/assets/images/icons/fast-forward.png"
                                        alt="Schneller Gutachten erstellen Icon" />
                                </div>
                                <div class="what-to-expect-feature-description">
                                    Schneller Gutachten
                                    <br />
                                    erstellen
                                </div>
                            </div>

                            <!--********** Automatic Selection of Text Building Blocks **********-->
                            <div class="what-to-expect-feature-container">
                                <div class="what-to-expect-feature-icon-container">
                                    <img
                                        src="/assets/images/icons/text-building-blocks.png"
                                        alt="Automatische Textbausteinauswahl in Gutachten Icon" />
                                </div>
                                <div class="what-to-expect-feature-description">
                                    Automatische
                                    <br />
                                    Textbausteinauswahl
                                </div>
                            </div>

                            <!--********** Great Photo Editing **********-->
                            <div class="what-to-expect-feature-container">
                                <div class="what-to-expect-feature-icon-container">
                                    <img
                                        src="/assets/images/icons/camera.png"
                                        alt="Fotobearbeitung für Sachverständige Icon" />
                                </div>
                                <div class="what-to-expect-feature-description">
                                    Blitzschnelle
                                    <br />
                                    Fotobearbeitung
                                </div>
                            </div>

                            <!--********** Report Layout **********-->
                            <div class="what-to-expect-feature-container">
                                <div class="what-to-expect-feature-icon-container">
                                    <img
                                        src="/assets/images/icons/license.png"
                                        alt="Professionelles Gutachten-Layout" />
                                </div>
                                <div class="what-to-expect-feature-description">
                                    Gutachten-Layout,
                                    <br />
                                    für das du Komplimente
                                    <br />
                                    bekommen wirst
                                </div>
                            </div>

                            <div id="what-to-expect-feature-link-container">
                                <a href="/kfz-sachverstaendigen-software">Alle Features &raquo;</a>
                            </div>
                        </div>
                        <div id="what-to-expect-screenshots">
                            <div
                                id="what-to-expect-video-autoixpert"
                                class="what-to-expect-screenshot fancy-tooltip"
                                title="Video abspielen"
                                (click)="openAutoixpertVideo()">
                                <img
                                    src="/assets/images/autoixpert-video-thumbnail.jpg"
                                    alt="Video Thumbnail KFZ-Gutachten Software autoiXpert" />
                            </div>
                            <div class="what-to-expect-screenshot fancy-tooltip" title="Zu den Screenshots">
                                <a href="/#Tour">
                                    <img
                                        src="/assets/images/gutachtenuebersicht-thumbnail.jpg"
                                        alt="Thumbnail KFZ-sachverständigensoftware autoiXpert Gutachtenliste" />
                                </a>
                            </div>
                            <a href="/#Tour">Zu den Screenshots &raquo;</a>
                        </div>
                    </div>
                </div>
            </div>
            <!--============================================-->
            <!-- END autoiXpert -->
            <!--============================================-->

            <!--============================================-->
            <!-- Partners -->
            <!--============================================-->
            <div id="what-to-expect-partners" class="col-md-4">
                <!--============================================-->
                <!-- DAT -->
                <!--============================================-->
                <div id="what-to-expect-calculation-providers" class="what-to-expect-card">
                    <div id="what-to-expect-calculation-provider-logos" class="what-to-expect-logo-container">
                        <img src="/assets/images/dat-logo-no-text.svg" alt="DAT Deutschland Logo" />
                        <img src="/assets/images/logos/audatex-qapter-calculation.png" alt="Audatex Qapter Logo" />
                    </div>
                    <div class="what-to-expect-text">
                        <p>
                            Starte in das Online-Zeitalter der Schadenkalkulation - mit SilverDAT 3, Audatex Qapter oder
                            GT Motive.
                        </p>
                        <p>
                            Im Webinar zeigen wir am Beispiel SilverDAT 3, wie
                            <strong>nahtlos</strong>
                            die Arbeit mit autoiXpert & Kalkulationsanbietern funktioniert.
                        </p>
                    </div>
                </div>
                <!--============================================-->
                <!-- END DAT -->
                <!--============================================-->
            </div>
            <!--============================================-->
            <!-- END Partners -->
            <!--============================================-->
        </div>
    </div>
</section>
