import moment from 'moment';
import 'moment/locale/de';

export function getEventsConfig(): EventConfig[] {
    /**
     * ATTENTION: Dates must respect summer/winter time in germany.
     * Summer time (+02:00): from last sunday in March til last sunday in October
     * Winter time (+01:00)
     */
    const freeProductWebinars = [
        // '2025-01-08T17:00:00+01:00',
        // '2025-02-03T17:00:00+01:00',
        '2025-03-11T17:00:00+01:00',
        '2025-04-03T16:00:00+02:00',
        '2025-05-08T16:00:00+02:00',
        '2025-06-10T17:00:00+02:00',
        '2025-07-09T17:00:00+02:00',
        '2025-08-05T17:00:00+02:00',
        '2025-09-18T16:00:00+02:00',
        '2025-10-21T17:00:00+02:00',
        '2025-11-11T17:00:00+01:00',
        '2025-12-11T16:00:00+01:00',
    ];

    // Events displayed on the start page. The first webinar is reachable under the URI "/webinar"
    const events: EventConfig[] = [
        ...freeProductWebinars.map((date) => {
            return {
                date,
                link: 'WEBINAR_DATE',
                title: 'Kostenfreies Produkt-Webinar',
            };
        }),

        //*****************************************************************************
        //  Expert Webinars
        //****************************************************************************/
        /**
         * If you create a new event page, you must also register it in app.routes.ts
         */

        getExpertWebinarEvent({
            date: '2025-02-25T17:00:00.000+01:00',
            htmlTemplateFileName: '/grundlagen-fahrzeugbewertung-kohl/',
            title: 'Grundlagen Fahrzeugbewertung', // - <b>ausgebucht</b>'
        }),

        getExpertWebinarEvent({
            date: '2025-03-18T17:00:00.000+01:00',
            htmlTemplateFileName: '/abtretung-mack-kohl/',
            title: 'Fach-Webinar Abtretung & Auftrag', // - <b>ausgebucht</b>'
        }),
        getExpertWebinarEvent({
            date: '2025-04-29T17:00:00.000+02:00',
            htmlTemplateFileName: '/Stellungnahmen-Kohl/',
            title: 'Fach-Webinar Stellungnahmen', // - <b>ausgebucht</b>',
        }),
        getExpertWebinarEvent({
            date: '2025-05-20T17:00:00.000+01:00',
            htmlTemplateFileName: '/wertdefinition-kohl/',
            title: 'Fach-Webinar Wertdefinition (WBW/RW/MW)', // - <b>ausgebucht</b>'
        }),
        getExpertWebinarEvent({
            date: '2025-06-16T16:00:00.000+02:00',
            htmlTemplateFileName: '/zweiradgutachten-kohl/',
            title: 'Fach-Webinar Motorrad', //  - <b>ausgebucht</b>'
        }),
        getExpertWebinarEvent({
            date: '2025-07-08T17:00:00.000+02:00',
            htmlTemplateFileName: '/zustandsberichte-kohl/',
            title: 'Fach-Webinar Zustandsberichte', //  - <b>ausgebucht</b>
        }),
        getExpertWebinarEvent({
            date: '2025-07-15T17:00:00.000+02:00',
            htmlTemplateFileName: '/Kalkulation-DAT-Kohl/',
            title: 'Fach-Webinar DAT-Kalkulation', //  - <b>ausgebucht</b>
        }),
        // TODO Implement web page as soon as contents have been provided by Patrick.
        // getExpertWebinarEvent({
        //     date: '2025-09-23T17:00:00.000+02:00',
        //     htmlTemplateFileName: '/Elektro-und-Hybrid-Kohl/',
        //     title: 'Fach-Webinar Elektro & Hybrid', // - <b>ausgebucht</b>'
        // }),
        getExpertWebinarEvent({
            date: '2025-10-14T17:00:00.000+02:00',
            htmlTemplateFileName: '/Audatex-Kalkulation-Kohl/',
            title: 'Fach-Webinar Audatex Qapter', // - <b>ausgebucht</b>'
        }),
        getExpertWebinarEvent({
            date: '2025-10-28T17:00:00.000+02:00',
            htmlTemplateFileName: '/Technisches-und-Beweissicherungsgutachten-Kohl/',
            title: 'Fach-Webinar Beweissicherung', // - <b>ausgebucht</b>'
        }),
        getExpertWebinarEvent({
            date: '2025-11-25T17:00:00.000+01:00',
            htmlTemplateFileName: '/Haftungsfälle-Haftungsfalle-Kohl/',
            title: 'Fach-Webinar Haftungsfälle & Haftungsfalle (mit Anwalt)', //  - <b>ausgebucht</b>'
        }),
        getExpertWebinarEvent({
            date: '2025-12-09T17:00:00.000+01:00',
            htmlTemplateFileName: '/Folierte-Fahrzeuge-Kohl/',
            title: 'Fach-Webinar folierte Fahrzeuge', //  - <b>ausgebucht</b>'
        }),
        getExpertWebinarEvent({
            date: '2025-12-16T17:00:00.000+01:00',
            htmlTemplateFileName: '/erfahrungsaustausch-fuer-sachverstaendige-schliefer/',
            title: 'Fachlicher Online-Austausch', // - <b>ausgebucht</b>'
        }),

        // Older webinars.
        //
        // getExpertWebinarEvent({
        //     date: '2024-10-01T17:00:00.000+02:00',
        //     htmlTemplateFileName: '/neuerungen-in-autoixpert-schliefer/',
        //     title: 'Neuerungen in autoiXpert', // - <b>ausgebucht</b>'
        // }),
        // getExpertWebinarEvent({
        //     date: '2024-10-29T17:00:00.000+01:00',
        //     htmlTemplateFileName: '/Power-User-Dokumente-Schliefer/',
        //     title: 'Power-User-Webinar Dokumente', // - <b>ausgebucht</b>'
        // }),
        getExpertWebinarEvent({
            date: '2025-03-17T17:00:00.000+01:00',
            htmlTemplateFileName: '/buchhaltung-schliefer/',
            title: 'Buchhaltung in autoiXpert - <b>ausgebucht</b>',
        }),
        getExpertWebinarEvent({
            date: '2025-11-12T17:00:00.000+01:00',
            htmlTemplateFileName: '/oldtimer-deuschle/',
            title: 'Fach-Webinar Oldtimer-Bewertung', // - <b>ausgebucht</b>'
        }),
        // getExpertWebinarEvent({
        //     date: '2024-11-25T17:00:00.000+01:00',
        //     htmlTemplateFileName: '/honorarkuerzungen-burkard/',
        //     title: 'Fach-Webinar Kürzungen (mit Anwalt) - <b>ausgebucht</b>',
        // }),
        // getExpertWebinarEvent({
        //     date: '2024-12-17T17:00:00.000+01:00',
        //     htmlTemplateFileName: '/textbausteine-im-gutachten-kohl/',
        //     title: 'Fach-Webinar Textbausteine', // - <b>ausgebucht</b>'
        // }),
        // getExpertWebinarEvent({
        //     date: '2025-01-21T17:00:00.000+01:00',
        //     htmlTemplateFileName: '/aufbauwebinar-fahrzeugbewertung-Kohl/',
        //     title: 'Grundlagen LKW-Gutachten - <b>ausgebucht</b>',
        // }),
        //
        // getExpertWebinarEvent({
        //     date                 : '2023-06-06T17:00:00.000+02:00',
        //     htmlTemplateFileName : '/kasko-1-kohl/',
        //     title                : 'Fach-Webinar Kasko (Teil 1)' //  - <b>ausgebucht</b>
        // }),
        //
        // getExpertWebinarEvent({
        //     date                 : '2023-08-01T17:00:00.000+02:00',
        //     htmlTemplateFileName : '/kasko-2-kohl/',
        //     title                : 'Fach-Webinar Kasko (Teil 2)' //  - <b>ausgebucht</b>
        // }),
        //
        // getExpertWebinarEvent({
        //     date                 : '2023-09-26T17:00:00.000+02:00',
        //     htmlTemplateFileName : '/fallstricke-schadenkalkulation-kohl/',
        //     title                : 'Fach-Webinar Fallstricke Kalkulation' //  - <b>ausgebucht</b>
        // }),
        //
        // getExpertWebinarEvent({
        //     date                 : '2023-11-15T17:00:00.000+01:00',
        //     htmlTemplateFileName : '/dokumentengestaltung-schliefer',
        //     title                : 'Kostenfreies Online-Webinar Gutachten-Design - <b>ausgebucht</b>'
        // }),
        //
    ].sort((eventA, eventB) => eventA.date.localeCompare(eventB.date));

    // Use a special hyphen character that's allowed to break earlier than a whitespace. The default hyphen is equally broken to the next line as a space.
    events.forEach((event) => (event.title = event.title.replaceAll(/-/g, '‑')));

    return (
        events
            // Include only future events (events from today are always displayed so running events can be looked up).
            .filter((event) => moment(event.date).isAfter(moment().startOf('day')))
            .map((event) => {
                // Add link to webinars
                if (event.link === 'WEBINAR_DATE') {
                    event.link = `/webinar/?webinarDate=${Buffer.from(event.date).toString('base64')}`;
                }

                return event;
            })
    );
}

export interface EventConfig {
    date: string;
    title: string;
    link?: string;
}

/**
 * Simplify writing the webinar event entry for paid expert webinars.
 * @param date - Date as ISO string.
 * @param htmlTemplateFile - path + name of the HTML file.
 * @param title - Title printed on the page.
 */
function getExpertWebinarEvent({
    date,
    htmlTemplateFileName,
    title,
}: {
    date: string;
    htmlTemplateFileName: string;
    title: string;
}): { date: string; link: string; title: string } {
    return {
        date,
        link: `/fachwebinare${htmlTemplateFileName}?webinarDate=${Buffer.from(date).toString('base64')}`,
        title,
    };
}
