<ax-webinar-hero
    [webinarDate]="webinarDate"
    [webinarTitleShort]="webinarTitleShort"
    [price]="price"
    [duration]="duration"></ax-webinar-hero>

<ax-webinar-agenda
    [maximumNumberOfParticipants]="maximumNumberOfParticipants"
    [webinarContents]="webinarContents"
    [webinarTitle]="webinarTitle">
    <p>
        Die Versicherer werden immer kreativer in ihrer Art, bei Honorar und Nebenkosten zu kürzen. Lassen Sie sich von
        uns Einblicke in die neueste Rechtsprechung geben und nehmen Sie das Handwerkszeug mit, um Ihre gerechtfertigten
        Ansprüche durchzusetzen.
    </p>
</ax-webinar-agenda>

<ax-webinar-target-group [targetGroupLevel]="targetGroupLevel">
    Für Kfz-Sachverständige, die Wissen rund um die jüngste Rechtsprechung erwerben oder vertiefen möchten.
</ax-webinar-target-group>

<ax-webinar-instructor-section [instructors]="instructors"></ax-webinar-instructor-section>

<ax-webinar-signup-section
    [webinarDate]="webinarDate"
    [duration]="duration"
    [price]="price"
    [webinarTitle]="webinarTitle"
    [webinarContents]="webinarContents"
    [instructor]="instructors[0].name"
    [eventOverbooked]="eventOverbooked"></ax-webinar-signup-section>

<ax-expert-webinar-faq></ax-expert-webinar-faq>
