import {Component, inject, OnInit, PLATFORM_ID} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {HttpClient} from '@angular/common/http';
import {WebinarHeroComponent} from '../../components/webinar-hero/webinar-hero.component';
import {
    WebinarTargetGroupComponent,
    WebinarTargetGroupLevel,
} from '../../components/webinar-target-group/webinar-target-group.component';
import {
    WebinarInstructor,
    WebinarInstructorSectionComponent,
} from '../../components/webinar-instructor-section/webinar-instructor-section.component';
import {WEBINAR_INSTRUCTOR_ANDREAS_SCHLIEFER} from '../../webinar-instructors';
import {WebinarAgendaComponent, WebinarContent} from '../../components/webinar-agenda/webinar-agenda.component';
import {getParameterByName} from '../../../../shared/lib/get-parameters-by-name';
import {FormsModule} from '@angular/forms';
import {WebinarSignupSectionComponent} from '../../components/webinar-signup-section/webinar-signup-section.component';
import {isPlatformBrowser} from '@angular/common';
import {ExpertWebinarFaqs} from "../../components/expert-webinar-faq/expert-webinar-faqs.component";

@Component({
    selector: 'ax-buchhaltung-schliefer',
    standalone: true,
    imports: [
        FormsModule,
        WebinarHeroComponent,
        WebinarAgendaComponent,
        WebinarTargetGroupComponent,
        WebinarHeroComponent,
        WebinarAgendaComponent,
        WebinarTargetGroupComponent,
        WebinarInstructorSectionComponent,
        WebinarSignupSectionComponent,
        ExpertWebinarFaqs,
    ],
    templateUrl: './buchhaltung-schliefer.component.html',
    styleUrl: '../fachwebinare.scss',
})
export class BuchhaltungSchlieferComponent implements OnInit {
    protected readonly titleService = inject(Title);
    protected readonly metaService = inject(Meta);
    protected readonly httpClient = inject(HttpClient);
    protected readonly platformId = inject(PLATFORM_ID);

    protected webinarDate: string | null = null;
    protected webinarTitle = 'Buchhaltung in autoiXpert';
    protected webinarTitleShort = 'Buchhaltung in aX';
    protected targetGroupLevel: WebinarTargetGroupLevel = {
        beginner: true,
        intermediate: true,
        professional: true,
    };
    protected instructors: Array<WebinarInstructor> = [WEBINAR_INSTRUCTOR_ANDREAS_SCHLIEFER];
    protected price: number = 0;
    protected duration = 1.5;
    protected maximumNumberOfParticipants = null; // 100;
    protected eventOverbooked = false;
    protected webinarContents: Array<WebinarContent> = [
        {
            title: 'Gutachtenrechnung',
            description: 'mit oder ohne eigene Positionen',
        },
        {
            title: 'freie Rechnung',
            description: 'für individuelle Dienstleistungen, z. B. Kaufbegleitung',
        },
        {
            title: 'Zahlungen',
            description: 'erfassen und managen',
        },
        {
            title: 'Mahnungen',
            description: 'Mahnstufen & -fristen',
        },
        {
            title: 'Kürzungen',
            description: 'Erfassung und Nachverfolgung',
        },
        {
            title: 'Gutschrift / Storno',
            description: 'Voll- oder Teilstorno / Gutschrift',
        },
        {
            title: 'DATEV-Export',
            description: 'Einfachere Zusammenarbeit mit dem Steuerberater',
        },
        {
            title: 'Bankkontoabgleich',
            description: 'Rechnungen bei Zahlungseingang auf deinem Konto automatisch als bezahlt markieren lassen',
        },
        {
            title: 'Auswertungen',
            description: 'Umsatz, Vermittler & Co.',
        },
    ];

    constructor() {
        this.titleService.setTitle(`${this.webinarTitleShort} | Kostenfreies Online-Webinar`);
        this.metaService.addTag({
            name: 'description',
            content: `Fachwebinar ${this.webinarTitle} Wissen für Kfz-Sachverständige - rund um autoiXpert`,
        });
    }

    ngOnInit() {
        if (isPlatformBrowser(this.platformId)) {
            this.webinarDate = atob(getParameterByName('webinarDate') ?? '');
        }
    }
}
