<!--============================================-->
<!-- Hero -->
<!--============================================-->
<section id="hero-section" class="wide-block">
    <div class="container">
        <div class="row">
            <div class="col-sm col-lg-5">
                <div id="hero-details-container">
                    <h3 class="additional-headline">Denke anders</h3>
                    <h1 id="hero-headline">Kfz-Gutachtensoftware für Mac &amp; iPad</h1>
                    <p id="hero-tagline">
                        Verzichte nicht auf deine Apple-Geräte, nur weil deine Gutachter-Software ausschließlich auf
                        Windows läuft.
                        <br class="d-none d-sm-inline" />
                        Mit autoiXpert arbeitest du nahtlos an Mac, iPad und iPhone, aber auch mit Android-Tablets und
                        Windows-PCs.
                    </p>
                </div>
            </div>

            <div class="col-sm col-lg-7 text-center align-self-center">
                <div class="hero-image-wrapper">
                    <div class="ax-on-imac-and-ipad-image">
                        <img id="hero-image" src="/assets/images/ax-on-imac.png" alt="autoiXpert auf einem Mac" />
                        <img
                            id="hero-image-ipad"
                            src="/assets/images/ax-on-iPad-large.png"
                            alt="autoiXpert auf einem iPad" />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div>
        <img id="hero-wave" src="/assets/images/multiple-waves.svg" alt="Wellen Hintergrund" />
    </div>
</section>
<!--============================================-->
<!-- END Hero -->
<!--============================================-->

<section class="feature-section">
    <div class="container">
        <div class="row align-items-center justify-content-around">
            <div class="col-md-8 col-lg-5">
                <div class="feature-description-container">
                    <div class="feature-main-text-container text-center">
                        <h3 class="additional-headline">Immer in Sync</h3>
                        <h2 class="feature-title">
                            Alle Geräte
                            <br class="d-none d-sm-inline" />
                            bestens abgestimmt
                        </h2>

                        <p class="feature-description">
                            Durch ihre moderne Technologie läuft die Software in deinem Browser genauso schnell wie
                            lokal installierte Software. Alle Daten sind automatisch online auf allen Geräten verfügbar.
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="row align-items-center justify-content-around">
            <div class="col-md-10">
                <div class="devices-card">
                    <h2 id="ax-gradient-text">
                        Du bestimmst
                        <br class="d-none d-sm-inline" />
                        das Gerät.
                    </h2>
                    <div class="devices-card-image-section">
                        <img
                            src="/assets/images/ax-on-mac.png"
                            class="fancy-tooltip"
                            alt="autoiXpert auf einem Mac"
                            title="MacOS - alle gängigen Browser (z. B. Safari, Chrome, Firefox, Brave)" />
                        <img
                            src="/assets/images/ax-on-macBook.png"
                            class="fancy-tooltip"
                            alt="autoiXpert auf einem macBook"
                            title="MacBook" />
                        <img
                            src="/assets/images/ax-on-iPad.png"
                            class="fancy-tooltip"
                            alt="autoiXpert auf einem iPad"
                            title="iPadOS - alle gängigen Browser (z. B. Safari, Chrome, Firefox, Brave)" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="feature-section">
    <div class="container">
        <div class="row">
            <div class="col-md">
                <div id="feature-section-intro-container">
                    <h3 class="additional-headline">Drei Sorgen weniger</h3>
                    <h2 id="feature-section-intro-heading">Typische Mac-Sorgen: gelöst.</h2>
                    <p id="feature-section-intro-text">
                        Wenn du bisher diese Workarounds nutzen musstest,
                        <br class="d-none d-lg-block" />
                        hast du nun drei Sorgen weniger.
                    </p>
                </div>
            </div>
        </div>
        <div id="feature-grid-length-limiter">
            <div class="row" data-row="1">
                <div class="col-md-4 feature-grid-item-container">
                    <div class="feature-grid-item">
                        <div class="feature-icon-container">
                            <i class="material-icons">connected_tv</i>
                        </div>
                        <h4 class="feature-name">Keine Bildschirm-Spiegelung</h4>
                        <div class="feature-description">
                            Keine Bildschirm-Spiegelung
                            <br class="d-none d-lg-block" />
                            von Windows zu Mac mit
                            <br class="d-none d-lg-block" />
                            Software wie Parallels
                            <br class="d-none d-lg-block" />
                            erforderlich.
                        </div>
                    </div>
                </div>
                <div class="col-md-4 feature-grid-item-container">
                    <div class="feature-grid-item">
                        <div class="feature-icon-container">
                            <i class="material-icons">tablet</i>
                        </div>
                        <h4 class="feature-name">Zugriff mit deinem iPad und iPhone</h4>
                        <div class="feature-description">
                            Greife auch mit deinem iPad oder iPhone
                            <br class="d-none d-lg-block" />
                            auf deine Gutachten, Kontakte &amp;
                            <br class="d-none d-lg-block" />
                            andere Daten zu.
                        </div>
                    </div>
                </div>
                <div class="col-md-4 feature-grid-item-container">
                    <div class="feature-grid-item">
                        <div class="feature-icon-container">
                            <i class="material-icons">desktop_windows</i>
                        </div>
                        <h4 class="feature-name">Gemacht für große Displays</h4>
                        <div class="feature-description">
                            Dein Monitor hat Platz, deine
                            <br class="d-none d-lg-block" />
                            bisherige Software nutzt ihn aber
                            <br class="d-none d-lg-block" />
                            nicht? Nutze mit autoiXpert die
                            <br class="d-none d-lg-block" />
                            gesamte Fensterbreite.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<ax-youtube-channel-box></ax-youtube-channel-box>

<section class="feature-section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-10">
                <div id="features-link-container">
                    <ax-exclamation-box
                        headline="Alles, was du als Gutachter brauchst"
                        buttonTitle="Alle Funktionen"
                        buttonLink="/kfz-sachverstaendigen-software"
                        secondButtonTitle="Kostenfrei testen"
                        secondButtonLink="/registrierung"
                        [noPadding]="true">
                        Textbausteine, Kalkulationen, Buchhaltung & mehr.
                    </ax-exclamation-box>
                </div>
            </div>
        </div>
    </div>
</section>
