<ax-webinar-hero
    [webinarDate]="webinarDate"
    [webinarTitleShort]="webinarTitleShort"
    [price]="price"
    [duration]="duration"></ax-webinar-hero>

<ax-webinar-agenda
    [maximumNumberOfParticipants]="maximumNumberOfParticipants"
    [webinarContents]="webinarContents"
    [webinarTitle]="webinarTitle">
    <p>
        Welchem Haftungsrisiko unterliege ich, wenn ich Gutachten erstelle? Wann kann ich für einen Vermögensschaden
        belangt werden? Dieses Webinar zeigt die Risiken auf und gibt Tipps, wie diese Risiken möglichst gering gehalten
        werden können.
        <br />
        Ein weiterer Themenkomplex wird das Thema Streitverkündung durch denKunden gegenüber dem Sachverständigen sein.
        Hierbei gibt es neben dem grundlegenden Verständnis zu dem Thema vor allem auch praxisorientierte Tipps zum
        Umgang mit einer Streitverkündung.
    </p>
</ax-webinar-agenda>

<ax-webinar-target-group [targetGroupLevel]="targetGroupLevel">
    Du solltest teilnehmen, wenn du in das Thema Haftung einsteigen oder dein Grundlagenwissen vertiefen möchtest.
</ax-webinar-target-group>

<ax-webinar-instructor-section [instructors]="instructors"></ax-webinar-instructor-section>

<ax-webinar-signup-section
    [webinarDate]="webinarDate"
    [duration]="duration"
    [price]="price"
    [webinarTitle]="webinarTitle"
    [webinarContents]="webinarContents"
    [instructor]="instructors[0].name"
    [eventOverbooked]="eventOverbooked"></ax-webinar-signup-section>

<ax-expert-webinar-faq></ax-expert-webinar-faq>
