import { Component } from '@angular/core';
import {AccordionComponent} from "../../../../shared/components/accordion/accordion.component";

@Component({
    selector: 'ax-expert-webinar-faq',
    standalone: true,
    imports: [AccordionComponent],
    templateUrl: './expert-webinar-faqs.component.html',
    styleUrl: './expert-webinar-faqs.component.scss',
})
export class ExpertWebinarFaqs {}
