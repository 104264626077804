import { afterNextRender, Component, inject, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { isPlatformServer, NgClass, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { fadeInAndOutAnimation } from '../../shared/animations/fade-in-and-out.animation';
import { moveOutLeft } from '../../shared/animations/move-out-left.animation';
import { moveInFromTopLeft } from '../../shared/animations/move-in-from-top-left.animation';
import { moveInFromBottomRight } from '../../shared/animations/move-in-from-bottom-right.animation';
import { HttpClient, HttpClientModule } from '@angular/common/http';

interface RegistrationStep {
    id:
        | 'enterEmail'
        | 'confirmEmail'
        | 'emailConfirmed'
        | 'contactDetails'
        | 'phoneNumber'
        | 'phoneNumberVerificationCode'
        | 'aboutYou'
        | 'setPassword'
        | 'registrationSuccessful';
    name:
        | 'E-Mail-Adresse'
        | 'E-Mail-bestaetigen'
        | 'E-Mail-bestaetigt'
        | 'Kontaktdaten'
        | 'Telefonnummer-bestaetigen'
        | 'SMS-Code-bestaetigen'
        | 'Ueber-dich'
        | 'Passwort-setzen'
        | 'Registrierung-erfolgreich';
}

interface RegistrationQueryParams {
    specialOffer?: string;
    emailVerificationToken?: string;
    _id?: string;
}

type CompanyType = 'assessor' | 'garage' | 'other';
type JobExperience = 'existingCompany' | 'newCompany' | 'other';
type MarketingChannel = 'search' | 'ads' | 'recommendation' | 'other';

@Component({
    selector: 'ax-registrierung',
    standalone: true,
    imports: [NgClass, FormsModule, HttpClientModule, NgIf],
    templateUrl: './registrierung.component.html',
    styleUrl: './registrierung.component.scss',
    animations: [fadeInAndOutAnimation(200, 300, 200), moveOutLeft(), moveInFromTopLeft(), moveInFromBottomRight()],
})
export class RegistrierungComponent {
    protected readonly titleService = inject(Title);
    protected readonly metaService = inject(Meta);
    protected readonly httpClient = inject(HttpClient);

    protected readonly platformId = inject(PLATFORM_ID);

    protected steps: RegistrationStep[] = [
        {
            id: 'enterEmail',
            name: 'E-Mail-Adresse',
        },
        {
            id: 'confirmEmail',
            name: 'E-Mail-bestaetigen',
        },
        {
            id: 'emailConfirmed',
            name: 'E-Mail-bestaetigt',
        },
        {
            id: 'contactDetails',
            name: 'Kontaktdaten',
        },
        {
            id: 'phoneNumber',
            name: 'Telefonnummer-bestaetigen',
        },
        {
            id: 'phoneNumberVerificationCode',
            name: 'SMS-Code-bestaetigen',
        },
        {
            id: 'aboutYou',
            name: 'Ueber-dich',
        },
        {
            id: 'setPassword',
            name: 'Passwort-setzen',
        },
        {
            id: 'registrationSuccessful',
            name: 'Registrierung-erfolgreich',
        },
    ];
    protected currentStep: RegistrationStep | null | undefined = null;

    // The ID of the registration process.
    protected userRegistrationId = null;
    protected teamId = null;
    protected emailVerificationToken: string | null | undefined = null;
    protected emailSubmissionPending = false;
    /**
     * @type {'tuevRheinland'}
     */
    protected specialOffer: string | null = null;
    // User Data
    protected email = null;
    protected firstName = null;
    protected lastName = null;
    protected organization = null;
    protected streetAndHouseNumber = null;
    protected zip = null;
    protected city = null;
    protected phone = null;
    protected phoneNumberVerificationCodeSending: boolean = false;
    protected phoneNumberTheSmsCodeHasBeenSentTo = null;
    protected phoneNumberVerificationCode: string | null = null;
    protected phoneNumberVerificationCodeCheckPending = false;
    protected userIsBeingInvited = null;
    /**
     * @type {'assessor'|'garage'|'other'}
     */
    protected companyType: CompanyType | null = null;
    /**
     * @type {'existingCompany' | 'newCompany' | 'other'}
     */
    protected jobExperience: JobExperience | null = null;
    /**
     * @type { 'search' | 'ads' | 'recommendation' | 'tradeFair' | 'other'}
     */
    protected marketingChannel: MarketingChannel | null = null;
    protected password = null;
    protected passwordVisible = false;
    protected accountCreationPending = false;

    // Server responses
    protected signupSuccessful = false;
    protected duplicate = false;

    // Query params
    protected queryParams: RegistrationQueryParams = {};

    constructor() {
        this.titleService.setTitle('Registrierung');
        this.metaService.addTag({
            name: 'description',
            content: 'Registrieren Sie sich für einen autoiXpert Test-Account.',
        });

        afterNextRender(() => {
            this.initialize();
        });
    }

    protected initialize() {
        this.extractQueryParams();

        if (this.queryParams.specialOffer) {
            this.specialOffer = this.queryParams.specialOffer;
        }

        // If the url contains a confirmation token, start with the email confirmed screen
        if (this.queryParams.emailVerificationToken && this.queryParams._id) {
            this.httpClient
                .get(
                    `/api/v0/userRegistrations/${this.queryParams._id}?emailVerificationToken=${this.queryParams.emailVerificationToken}`,
                )
                .subscribe({
                    next: (data: any) => {
                        this.email = data.contactPerson.email;
                        this.firstName = data.contactPerson.firstName;
                        this.lastName = data.contactPerson.lastName;
                        this.organization = data.contactPerson.organization;
                        this.streetAndHouseNumber = data.contactPerson.streetAndHouseNumberOrLockbox;
                        this.zip = data.contactPerson.zip;
                        this.city = data.contactPerson.city;
                        this.userIsBeingInvited = data.invitedBy && data.invitedBy.email;

                        this.userRegistrationId = data._id;
                        this.teamId = data.teamId;
                        this.emailVerificationToken = this.queryParams.emailVerificationToken;
                        this.currentStep = this.steps.find((step) => step.id === 'emailConfirmed');

                        this.removeUnnecessaryStepsForColleagues();
                    },
                    error: (jqXHR) => {
                        const error = jqXHR.error;
                        switch (error.code) {
                            default:
                                // Allow vue.js to render once before the alert appears.
                                setTimeout(() => {
                                    alert(
                                        `Deine E-Mail-Adresse konnte nicht validiert werden. Bitte wende dich an die autoiXpert Hotline.\n\nDu wirst jetzt zur Kontaktseite weitergeleitet.`,
                                    );
                                    this.redirectToContactScreen();
                                }, 1);
                        }
                    },
                });
        } else {
            this.currentStep = this.steps[0];
        }
    }

    protected proceedToEmailConfirmation() {
        if (this.sendingWelcomeFormAllowed()) {
            this.emailSubmissionPending = true;

            this.httpClient
                .post(
                    '/api/v0/userRegistrations',
                    JSON.stringify({
                        contactPerson: {
                            email: this.email,
                        },
                        specialOffer: this.specialOffer,
                    }),
                    {
                        headers: {
                            'Content-Type': 'application/json; charset=utf-8',
                        },
                    },
                )
                .subscribe({
                    next: (data: any) => {
                        this.userRegistrationId = data._id;

                        // The server removes punycode from email addresses which is relevant for umlauts in domains such as in @sachverständiger-brodbeck.de
                        this.email = data.contactPerson.email;

                        this.proceedToNextStep();
                        this.emailSubmissionPending = false;
                    },
                    error: (jqXHR) => {
                        const error = jqXHR.error;
                        switch (error.code) {
                            case 'DUPLICATE_EMAIL_ADDRESS':
                                alert(
                                    `Diese E-Mail-Adresse ist bereits registriert. Bitte rufe die autoiXpert-Hotline an oder schreibe uns.`,
                                );
                                this.redirectToContactScreen();
                                break;
                            case 'TOO_MANY_RESENT_EMAILS':
                                alert(
                                    'Für diese Mailadresse wurden bereits fünf Bestätigungs-Mails verschickt. Bitte prüfe deinen Spam oder kontaktiere die autoiXpert-Hotline.',
                                );
                                break;
                            case 'DISPOSABLE_EMAIL_PROVIDER':
                                alert(
                                    `Bitte verwende keine Einmal-Adresse. Wir verschicken keinen Spam. Aus allen E-Mails (z. B. Feature-Updates) kannst du dich austragen lassen.`,
                                );
                                break;
                            case 'REGISTRATION_FOR_SPECIAL_OFFER_OUTSIDE_OFFER_PERIOD':
                                alert(
                                    `Das Spezial-Angebot kann aktuell nicht gebucht werden, weil der Aktionszeitraum abgelaufen ist.`,
                                );
                                break;
                            default:
                                alert(
                                    `Die Registrierung ist aktuell nur manuell möglich. Bitte rufe die autoiXpert-Hotline an oder schreibe uns.\n\nDu wirst jetzt zur Kontaktseite weitergeleitet.`,
                                );
                                this.redirectToContactScreen();
                        }
                        this.emailSubmissionPending = false;
                    },
                });
        }
    }

    protected resendConfirmationEmail() {
        this.httpClient
            .post(
                '/api/v0/resentEmails',
                JSON.stringify({
                    userRegistrationId: this.userRegistrationId,
                }),
                {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                    },
                },
            )
            .subscribe({
                next: () => {
                    alert(`Die E-Mail wurde erneut versendet an "${this.email}".`);
                },
                error: (jqXHR) => {
                    const error = jqXHR.error;
                    switch (error.code) {
                        default:
                            alert(
                                `Die E-Mail kann maximal dreimal versendet werden. Bitte wende dich an die autoiXpert-Hotline.\n\nDu wirst jetzt zur Kontaktseite weitergeleitet.`,
                            );
                            this.redirectToContactScreen();
                    }
                },
            });
    }

    //*****************************************************************************
    //  Phone Number
    //****************************************************************************/
    protected isPhoneNumberValid() {
        const form = document.getElementById('registration-phone-number-step-form') as HTMLFormElement;
        if (form) {
            return form.checkValidity(); // form.valid TODO: why did that work? .valid is not a prop of form
        }
        return false;
    }

    protected sendPhoneNumberVerificationCode(isFirstCode: boolean) {
        if (this.isPhoneNumberValid()) {
            this.phoneNumberVerificationCodeSending = true;

            this.httpClient
                .post(
                    '/api/v0/sendPhoneNumberVerificationCodeViaSms',
                    JSON.stringify({
                        userRegistrationId: this.userRegistrationId,
                        emailVerificationToken: this.emailVerificationToken,
                        phoneNumber: this.phone,
                    }),
                    {
                        headers: {
                            'Content-Type': 'application/json; charset=utf-8',
                        },
                    },
                )
                .subscribe({
                    next: (data: any) => {
                        this.phoneNumberTheSmsCodeHasBeenSentTo = data.phoneNumberTheSmsCodeHasBeenSentTo;

                        // Server sends verification code only on development environment.
                        if (data.verificationCode) {
                            alert(
                                `Keine SMS gesendet (da Entwicklungsumgebung).\n\nVerificationCode: ${data.verificationCode}`,
                            );
                        }
                        if (isFirstCode) {
                            this.proceedToNextStep();
                        } else {
                            alert(`Code erneut verschickt an ${this.phoneNumberTheSmsCodeHasBeenSentTo}`);
                        }
                        this.phoneNumberVerificationCodeSending = false;

                        // As soon as the SMS arrives, the browser can read it.
                        if ('credentials' in navigator) {
                            navigator.credentials
                                .get({
                                    otp: { transport: ['sms'] },
                                } as CredentialRequestOptions)
                                .then((otp) => {
                                    if (otp) {
                                        this.phoneNumberVerificationCode = (otp as any).code;
                                        this.proceedToNextStep();
                                    }
                                });
                        } else {
                            console.log('navigator.credentials is not supported by this browser or insecure origin.');
                        }
                    },
                    error: (jqXHR) => {
                        const error = jqXHR.error;
                        switch (error.code) {
                            case 'DUPLICATE_PHONE_NUMBER':
                                alert(
                                    `Mit dieser Telefonnummer wurde bereits ein Test-Account angelegt. Bitte rufe die autoiXpert-Hotline an oder schreibe uns.`,
                                );
                                this.redirectToContactScreen();
                                break;
                            case 'TOO_MANY_RESENT_SMS_CODES':
                                alert(
                                    'Für diese Telefonnummer wurden bereits drei Bestätigungs-SMS verschickt. Bitte prüfe dein Smartphone oder kontaktiere die autoiXpert-Hotline.',
                                );
                                break;
                            case 'INVALID_GERMAN_PHONE_NUMBER':
                                alert(
                                    `Das scheint keine gültige deutsche Handynummer zu sein. Bitte stelle sicher, dass das Format richtig ist.\n\nWir können aktuell nur Kunden in Deutschland bedienen.`,
                                );
                                break;
                            default:
                                alert(
                                    `Fehler bei der Registrierung. Bitte rufe die autoiXpert-Hotline an oder schreibe uns.\n\nDu wirst jetzt zur Kontaktseite weitergeleitet.`,
                                );
                                this.redirectToContactScreen();
                        }
                        this.phoneNumberVerificationCodeSending = false;
                    },
                });
        }
    }

    protected checkPhoneNumberVerificationCode() {
        if (this.isPhoneNumberVerificationCodeValid()) {
            this.phoneNumberVerificationCodeCheckPending = true;

            this.httpClient
                .post(
                    '/api/v0/confirmPhoneNumberVerificationCode',
                    JSON.stringify({
                        userRegistrationId: this.userRegistrationId,
                        emailVerificationToken: this.emailVerificationToken,
                        verificationCode: this.phoneNumberVerificationCode,
                    }),
                    {
                        headers: {
                            'Content-Type': 'application/json; charset=utf-8',
                        },
                    },
                )
                .subscribe({
                    next: () => {
                        this.proceedToNextStep();
                    },
                    error: (jqXHR) => {
                        const error = jqXHR.error;
                        switch (error.code) {
                            case 'INVALID_VERIFICATION_CODE':
                                alert(
                                    `Der Code war leider nicht richtig. Gib bitte den Code aus der letzten SMS ein. Du hast maximal drei Versuche.`,
                                );
                                break;
                            case 'MAXIMUM_NUMBER_OF_CODE_INPUT_ATTEMPTS_REACHED':
                                alert(
                                    `Du hast bereits die maximale Anzahl an Versuchen erreicht. Bitte kontaktiere die Hotline.`,
                                );
                                this.redirectToContactScreen();
                                break;
                            default:
                                alert(
                                    `Fehler bei der Validierung deines Codes. Bitte rufe die autoiXpert-Hotline an oder schreibe uns.\n\nDu wirst jetzt zur Kontaktseite weitergeleitet.`,
                                );
                                this.redirectToContactScreen();
                        }
                        this.phoneNumberVerificationCodeCheckPending = false;
                    },
                });
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Phone Number
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Check About You Data
    //****************************************************************************/
    /**
     * Data like
     * - type of office
     * - work experience
     * - marketing channel
     */
    protected checkAboutYouData() {
        if (this.aboutYouDataComplete()) {
            this.proceedToNextStep();
        } else {
            alert('Bitte fülle alle Angaben vollständig aus.');
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Check About You Data
    /////////////////////////////////////////////////////////////////////////////*/

    /**
     * Only the main registration (first user; team registration) requires all steps.
     *
     * Unnecessary steps for secondary registrations:
     * - phone number verification (2 screens)
     * - about you (= what size is you business, what's your background?). We already have the info.
     */
    protected removeUnnecessaryStepsForColleagues() {
        if (this.userIsBeingInvited) {
            this.steps = this.steps.filter(
                (step) => !['phoneNumber', 'phoneNumberVerificationCode', 'aboutYou'].includes(step.id),
            );
        }
    }

    protected togglePasswordVisibility() {
        this.passwordVisible = !this.passwordVisible;
    }

    protected createUserAccount() {
        if (!(this.password || '').trim()) {
            alert('Bitte vergib ein Passwort.');
            return;
        }

        this.createAccount();
    }

    protected appOeffnen() {
        window.open(this.appUrl());
    }

    protected proceedToNextStep() {
        if (this.currentStep) {
            // Check if the entered data is complete.
            if (this.currentStep.id === 'contactDetails') {
                if (!this.sendContactDataFormAllowed()) {
                    alert('Bitte gib deine Adressdaten vollständig ein.');
                    return;
                }
            }

            const indexOfCurrentStep = this.steps.indexOf(this.currentStep);
            this.currentStep = this.steps[indexOfCurrentStep + 1];
        }
    }

    protected returnToPreviousStep() {
        if (this.currentStep) {
            const indexOfCurrentStep = this.steps.indexOf(this.currentStep);
            this.currentStep = this.steps[indexOfCurrentStep - 1];
        }
    }

    protected redirectToContactScreen() {
        window.location.href = '/Kontakt';
    }

    protected selectCompanyType(companyType: CompanyType) {
        this.companyType = companyType;
    }

    protected selectJobExperience(jobExperience: JobExperience) {
        this.jobExperience = jobExperience;
    }

    protected selectMarketingChannel(marketingChannel: MarketingChannel) {
        this.marketingChannel = marketingChannel;
    }

    protected createAccount() {
        this.signupSuccessful = false;
        this.duplicate = false;
        this.accountCreationPending = true;

        this.httpClient
            .patch(
                `/api/v0/userRegistrations/${this.userRegistrationId}?emailVerificationToken=${this.emailVerificationToken}`,
                JSON.stringify({
                    // the string notation "contactPerson.lastName" makes sure that the contactPerson object is not simply replaced on the server
                    // https://docs.mongodb.com/manual/reference/operator/update/set/#set-fields-in-embedded-documents
                    'contactPerson.lastName': this.lastName,
                    'contactPerson.firstName': this.firstName,
                    'contactPerson.organization': this.organization,
                    'contactPerson.streetAndHouseNumberOrLockbox': this.streetAndHouseNumber,
                    'contactPerson.zip': this.zip,
                    'contactPerson.city': this.city,
                    'contactPerson.phone': this.phone,
                    // About You Data
                    companyType: this.companyType,
                    jobExperience: this.jobExperience,
                    marketingChannel: this.marketingChannel,

                    emailVerified: true,
                    password: this.password,
                }),
                {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                    },
                },
            )
            .subscribe({
                next: () => {
                    this.signupSuccessful = true;
                    this.accountCreationPending = false;
                    this.proceedToNextStep();
                },
                error: (jqXHR) => {
                    const error = jqXHR.error;
                    switch (error.code) {
                        case 'MISSING_ACCOUNT_CONFIRMATION_TOKEN':
                        case 'WRONG_ACCOUNT_CONFIRMATION_TOKEN':
                            alert(
                                'Die Registrierung wurde bereits abgeschlossen. Änderungen an Adressdaten kannst du in den Einstellungen deines Accounts vornehmen.',
                            );
                            break;
                        case 'NO_DAT_TEST_ACCOUNTS_AVAILABLE':
                            alert(
                                'Aktuell stehen keine DAT-Testaccounts zur Verfügung. Bitte kontaktiere die autoiXpert-Hotline.',
                            );
                            break;
                        case 'USER_EXISTS':
                            alert(
                                `Es gibt bereits einen Nutzer (aber keine Registrierung) mit dieser E-Mail-Adresse. Bitte rufe die autoiXpert-Hotline an oder schreibe uns.`,
                            );
                            break;
                        case 'TEAM_NAME_EXISTS':
                            alert(
                                'Es gibt bereits ein Büro an dieser Adresse. Bitte wende dich an den Administrator dieses Büros, um Zugriff zu bekommen. Der Admin kann Einladungen in seinen autoiXpert-Einstellungen versenden.',
                            );
                            break;
                        case 'INCOMPLETE_DATA':
                            alert(
                                'Die Registrierungsdaten sind unvollständig. Bitte kontaktiere die autoiXpert-Hotline.',
                            );
                            break;
                        default:
                            alert(
                                'Die Registrierung konnte nicht gespeichert werden. Bitte kontaktiere die autoiXpert-Hotline.',
                            );
                    }
                    this.accountCreationPending = false;
                },
            });
    }

    protected extractQueryParams() {
        if (!isPlatformServer(this.platformId)) {
            const searchQuery = window.location.search.substring(1);
            const searchRegEx = /([^&=]+)=?([^&]*)/g;

            let match: RegExpExecArray | null;
            while ((match = searchRegEx.exec(searchQuery))) {
                this.queryParams[match[1] as keyof RegistrationQueryParams] = decodeURIComponent(match[2]);
            }
        }
    }

    protected sendingWelcomeFormAllowed() {
        return !!this.email;
    }

    protected sendContactDataFormAllowed() {
        return (
            (this.firstName || '').trim() &&
            (this.lastName || '').trim() &&
            (this.organization || '').trim() &&
            (this.streetAndHouseNumber || '').trim() &&
            (this.zip || '').trim() &&
            (this.city || '').trim() &&
            // If the user is being invited, the phone number does not need to be validated, but the user must enter one.
            (this.userIsBeingInvited ? this.phone : true)
        );
    }

    protected isPhoneNumberVerificationCodeValid() {
        return this.phoneNumberVerificationCode && this.phoneNumberVerificationCode.length === 4;
    }

    protected aboutYouDataComplete() {
        return this.companyType && this.jobExperience && this.marketingChannel;
    }

    protected setPasswordAllowed() {
        return !!this.password;
    }

    protected isMobile() {
        if (isPlatformServer(this.platformId)) {
            return false;
        } else {
            return window.matchMedia('(max-width: 768px)').matches;
        }
    }

    protected appUrl() {
        /**
         * If the app is running on a local environment, use the local app URL.
         */
        if (window.location.href.includes('autoixpert.lokal') || window.location.href.includes('localhost')) {
            return `https://autoixpert.lokal/Login?user=${this.email}`;
        }
        return `https://app.autoixpert.de/Login?user=${this.email}`;
    }
}
