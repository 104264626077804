<ax-webinar-hero
    [webinarDate]="webinarDate"
    [webinarTitleShort]="webinarTitleShort"
    [price]="price"
    [duration]="duration"></ax-webinar-hero>

<ax-webinar-agenda
    [maximumNumberOfParticipants]="maximumNumberOfParticipants"
    [webinarContents]="webinarContents"
    [webinarTitle]="webinarTitle">
    <p>
        In diesem Webinar gehen wir im Detail eine vollständige Kalkulation durch und zeigen, was erneuern,
        instandsetzen und lackieren bedeutet. Wir erklären, was individuelle Positionen sind, wann die IFL-Liste zum
        Einsatz kommt und wann es sinnvoll ist, vorhandene Positionen abzuändern. Im weiteren Verlauf wird ein
        gesonderter Blick auf die vorgangsbezogenen Einstellungen, sowie die Möglichkeit des Anlegens büroindividueller
        Positionen besprochen. In einem weiteren Schritt wird die Möglichkeit der Durchführung einer Phantomkalkulation
        beziehungsweise einer manuellen, freien Kalkulation an einem Beispiel erarbeitet.
    </p>
</ax-webinar-agenda>

<ax-webinar-target-group [targetGroupLevel]="targetGroupLevel">
    Du solltest teilnehmen, wenn du in das Thema DAT-Kalkulation einsteigen oder dein Grundlagenwissen auffrischen
    möchtest.
</ax-webinar-target-group>

<ax-webinar-instructor-section [instructors]="instructors"></ax-webinar-instructor-section>

<ax-webinar-signup-section
    [webinarDate]="webinarDate"
    [duration]="duration"
    [price]="price"
    [webinarTitle]="webinarTitle"
    [webinarContents]="webinarContents"
    [instructor]="instructors[0].name"
    [eventOverbooked]="eventOverbooked"></ax-webinar-signup-section>

<ax-expert-webinar-faq></ax-expert-webinar-faq>
