import {Component, inject, PLATFORM_ID} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {HttpClient} from '@angular/common/http';
import {WebinarHeroComponent} from '../../components/webinar-hero/webinar-hero.component';
import {
    WebinarTargetGroupComponent,
    WebinarTargetGroupLevel,
} from '../../components/webinar-target-group/webinar-target-group.component';
import {
    WebinarInstructor,
    WebinarInstructorSectionComponent,
} from '../../components/webinar-instructor-section/webinar-instructor-section.component';
import {WEBINAR_INSTRUCTOR_PATRICK_KOHL} from '../../webinar-instructors';
import {WebinarAgendaComponent, WebinarContent} from '../../components/webinar-agenda/webinar-agenda.component';
import {getParameterByName} from '../../../../shared/lib/get-parameters-by-name';
import {FormsModule} from '@angular/forms';
import {WebinarSignupSectionComponent} from '../../components/webinar-signup-section/webinar-signup-section.component';
import {getMetaDescriptionForTargetGroup} from '../../get-meta-description-for-target-group';
import {isPlatformBrowser} from '@angular/common';
import {ExpertWebinarFaqs} from '../../components/expert-webinar-faq/expert-webinar-faqs.component';

@Component({
    selector: 'ax-dat-kalkulation-kohl',
    standalone: true,
    imports: [
        FormsModule,
        WebinarHeroComponent,
        WebinarAgendaComponent,
        WebinarTargetGroupComponent,
        WebinarHeroComponent,
        WebinarAgendaComponent,
        WebinarTargetGroupComponent,
        WebinarInstructorSectionComponent,
        WebinarSignupSectionComponent,
        ExpertWebinarFaqs,
    ],
    templateUrl: './dat-kalkulation-kohl.component.html',
    styleUrl: '../fachwebinare.scss',
})
export class DatKalkulationKohlComponent {
    protected readonly titleService = inject(Title);
    protected readonly metaService = inject(Meta);
    protected readonly httpClient = inject(HttpClient);
    protected readonly platformId = inject(PLATFORM_ID);

    protected webinarDate: string | null = null;
    protected webinarTitle = 'Arbeiten mit der DAT-Kalkulation';
    protected webinarTitleShort = 'DAT-Kalkulation';
    protected targetGroupLevel: WebinarTargetGroupLevel = {
        beginner: true,
        intermediate: false,
        professional: false,
    };
    protected instructors: Array<WebinarInstructor> = [WEBINAR_INSTRUCTOR_PATRICK_KOHL];
    protected price: number = 149;
    protected duration = 2;
    protected maximumNumberOfParticipants = 60;
    protected eventOverbooked = false;
    protected webinarContents: Array<WebinarContent> = [
        {
            title: 'Grundeinstellungen',
            description: '',
        },
        {
            title: 'Grundlagen der Kalkulation',
            description: '',
        },
        {
            title: 'Individuelle Positionen',
            description: '',
        },
        {
            title: 'Phantomkalkulation',
            description: '',
        },
        {
            title: 'Freie Kalkulation',
            description: '',
        },
    ];

    constructor() {
        this.titleService.setTitle(`${this.webinarTitle} | Fach-Webinar`);
        this.metaService.addTag({
            name: 'description',
            content: getMetaDescriptionForTargetGroup(this.targetGroupLevel),
        });
    }

    ngOnInit() {
        if (isPlatformBrowser(this.platformId)) {
            this.webinarDate = atob(getParameterByName('webinarDate') ?? '');
        }
    }
}
