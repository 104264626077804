<ax-google-structured-data [json]="googleStructuredData" />
<!--============================================-->
<!-- Hero -->
<!--============================================-->
<section id="hero-section" class="wide-block">
    <div class="container">
        <div class="row">
            <div class="col-sm col-lg-5">
                <div id="hero-job-details-container">
                    <div class="all-positions-occupied-note">
                        Alle Stellen besetzt.
                        <!--                        <div class="unsolicited-application-possible">Initiativbewerbung möglich.</div>-->
                    </div>

                    <h3 class="additional-headline">Werde Teil unseres Teams</h3>
                    <h1>Organisationstalent</h1>
                    <div>
                        Orga, Reisekosten & Kommunikation
                        <br />
                        mit unserem Steuerberater
                    </div>
                    <div class="label">Start sofort möglich - m/w/d</div>

                    <div id="highlight-tags">
                        <!--********** Remote **********-->
                        <div
                            class="highlight-tag fancy-tooltip"
                            title="Als Organisationstalent arbeitest du bei uns weitestgehend remote. Wir treffen uns alle 3-4 Wochen vor Ort in modernen Co-Working Spaces. In Teilzeit musst du aber nicht allzu oft anreisen.">
                            <i class="material-icons-outlined">home</i>
                            90 % Remote
                        </div>

                        <!--********** Hours **********-->
                        <div
                            class="highlight-tag fancy-tooltip"
                            title="Bei uns kannst du deine Stunden flexibel wählen.">
                            <i class="material-icons-outlined">more_time</i>
                            4-8 h Woche
                        </div>

                        <!--********** Salary **********-->
                        <div
                            class="highlight-tag fancy-tooltip"
                            title="Je mehr Stunden du als Organisationstalent bei uns leistest, desto mehr Gehalt kannst du verdienen. Wenn du unter der 520€-Grenze bleibst, kannst du das Geld sogar steuerfrei bekommen.">
                            <i class="material-icons-outlined">euro_symbol</i>
                            520 €-Basis (20 €/h)
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm col-lg-7">
                <img
                    id="hero-image"
                    src="/assets/images/team-with-imac-and-ipad.jpg"
                    alt="autoiXpert Produkt & Founder-Team - Organisationstalent" />
            </div>
        </div>
    </div>

    <div>
        <img id="hero-wave" src="/assets/images/multiple-waves.svg" alt="Wellen Hintergrund" />
    </div>
</section>
<!--============================================-->
<!-- END Hero -->
<!--============================================-->

<!--============================================-->
<!-- What We Do -->
<!--============================================-->
<section>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-sm col-lg-7 text-center">
                <h3 class="additional-headline">Was tun wir?</h3>
                <h2>Stell mit uns die Branche auf den Kopf!</h2>
                <p>
                    autoiXpert ist eine Web-App für eine der spannendsten Branchen Deutschlands: Die Automobil-Branche.
                    Mit autoiXpert erstellen Kfz-Sachverständige Gutachten für Fahrzeuge nach einem Unfallschaden oder
                    bewerten ausgefallene Oldtimer vom
                    <a href="https://www.google.de/search?q=mercedes+sl+300&tbm=isch" target="_blank" rel="noopener">
                        Mercedes SL 300
                    </a>
                    bis zum
                    <a href="https://www.google.de/search?q=aston+martin+db5&tbm=isch" target="_blank" rel="noopener">
                        Aston Martin DB5</a>, dessen Gaspedal schon James Bond aufs Blech drückte.
                </p>
            </div>
        </div>

        <!--********** More Info on Our Product **********-->
        <div class="row justify-content-center margin-">
            <div class="col-sm col-lg-7 text-center">
                <h3 id="more-info-on-our-product-heading">Mehr Infos zu unserem Produkt</h3>

                <div id="more-info-on-our-product-row">
                    <!--********** Home Page **********-->
                    <a href="/" class="more-info-on-our-product-item" target="_blank">
                        <img
                            src="/assets/images/screenshots/homepage-autoixpert-thumbnail.png"
                            alt="Screenshot Startseite autoiXpert" />
                        Startseite
                    </a>

                    <!--********** Brandl Customer Video **********-->
                    <a
                        href="https://www.youtube.com/@autoiXpert"
                        class="more-info-on-our-product-item"
                        target="_blank"
                        rel="noopener">
                        <img
                            src="/assets/images/screenshots/brandl-video-thumbnail.png"
                            alt="Screenshot Brandl Video" />
                        YouTube
                    </a>
                </div>
            </div>
        </div>

        <!--********** Who are we? **********-->
        <div id="who-are-we-row" class="row justify-content-center">
            <div class="col-sm col-lg-7 text-center">
                <h3 class="additional-headline">Wer sind wir?</h3>
                <h2>Ein junges dynamisches Team</h2>
                <p>
                    Wir sind ein junges Team aus sieben Personen zwischen 26 und 39 Jahren (5 Jungs + 2 Mädels), das
                    moderne Software für Kfz-Sachverständige baut. Uns gibt es seit 2019: Bei uns bekommst du eine
                    einmalige Mischung aus Startup-Spirit mit starker Marktdurchdringung und stabilem, hohem Umsatz.
                </p>
            </div>
        </div>
    </div>
</section>
<!--============================================-->
<!-- END What We Do -->
<!--============================================-->

<!--============================================-->
<!-- Who should work at autoiXpert? -->
<!--============================================-->
<section id="who-should-work-at-autoixpert-section" class="dark-section">
    <div class="container">
        <div class="row justify-content-center align-items-center">
            <div class="col col-lg-6 order-2 order-lg-1">
                <!--********** Items **********-->
                <div id="who-should-work-at-autoixpert-items-container">
                    <!--********** Diligence **********-->
                    <div class="who-should-work-at-autoixpert-item">
                        <i class="material-icons-outlined text-with-dawn-blue-gradient">emoji_events</i>
                        <div>Du bist sorgfältig.</div>
                    </div>

                    <!--********** Change Industry **********-->
                    <div class="who-should-work-at-autoixpert-item">
                        <i class="material-icons-outlined text-with-dawn-blue-gradient">rocket_launch</i>
                        <div>Du bist gut in allem, was du dir vornimmst.</div>
                    </div>

                    <!--********** Smart **********-->
                    <div class="who-should-work-at-autoixpert-item">
                        <i class="material-icons-outlined text-with-dawn-blue-gradient">lightbulb</i>
                        <div>Du kommst immer auf gute Ideen.</div>
                    </div>

                    <!--********** Team Player **********-->
                    <div class="who-should-work-at-autoixpert-item">
                        <i class="material-icons-outlined text-with-dawn-blue-gradient">sports_soccer</i>
                        <div>
                            Du bist ein
                            <br />
                            Team-Player.
                        </div>
                    </div>
                </div>
            </div>

            <div class="col col-lg-6 order-1 order-lg-2">
                <div id="who-should-work-at-autoixpert-text-container">
                    <h2 class="text-with-dawn-blue-gradient">Wer sollte bei autoiXpert arbeiten?</h2>
                    <p>
                        autoiXpert definiert sich über Lust auf Arbeit, persönliches Wachstum und dem Streben nach
                        hervorragender Software.
                    </p>
                    <p>
                        Wenn du den Drive hast mit uns eine ganze Branche auf den Kopf zu stellen und ein
                        Leistungsträger mit erstklassigen Fähigkeiten bist, bist du genau der oder die Richtige für
                        unser Team.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
<!--============================================-->
<!-- END Who should work at autoiXpert? -->
<!--============================================-->

<!--============================================-->
<!-- Qualifications -->
<!--============================================-->
<section class="section-with-background">
    <div class="container">
        <div class="row justify-content-center mb-4">
            <div class="col-sm text-center">
                <h3 class="additional-headline">Deine Qualifikationen</h3>
                <h2>Was solltest du mitbringen?</h2>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-7">
                <!--============================================-->
                <!-- Main Qualifications -->
                <!--============================================-->

                <!--********** Diligence & Reliability **********-->
                <div id="qualification-sustainable-solutions" class="qualification-item">
                    <span class="qualification-emoji">🎀</span>
                    <!-- prettier-ignore -->
                    <div class="qualification-item-text">
                        Du bist äußerst
                        <strong>sorgfältig & zuverlässig</strong>.
                    </div>
                    <span
                        class="help-indicator fancy-tooltip"
                        title="Unsere Team-Orga übernimmst du zuverlässig und beantwortest Nachfragen unseres Steuerberaters stets gewissenhaft. Du bist ein Ass in der Selbstorganisation.">
                        ?
                    </span>
                </div>

                <!--********** Reticence **********-->
                <div id="qualification-careful-reliable" class="qualification-item">
                    <span class="qualification-emoji">🤫</span>
                    <div class="qualification-item-text">
                        <strong>Verschwiegenheit</strong>
                        ist dein zweiter Vorname.
                    </div>
                    <span
                        class="help-indicator fancy-tooltip"
                        title="Bei uns bekommst du umfassende Einblicke in unsere Geschäftszahlen. Deshalb zählen wir auf deine Verschwiegenheit.">
                        ?
                    </span>
                </div>

                <!--********** Solution-oriented **********-->
                <div id="qualification-solution-oriented" class="qualification-item">
                    <span class="qualification-emoji">✅</span>
                    <!-- prettier-ignore -->
                    <div class="qualification-item-text">
                        Du verstehst dich als
                        <strong>Problemlöser</strong>.
                    </div>
                    <span
                        class="help-indicator fancy-tooltip"
                        title="Wir lieben Automatisierung - deshalb arbeitest du bei uns auch heute schon z. B. in der Buchhaltung mit modernsten Tools. Wenn du noch Raum für Verbesserungen findest, immer her damit! Wir haben die Expertise, unsere eigenen Software-Lösungen immer weiter zu verfeinern.">
                        ?
                    </span>
                </div>

                <!--********** Clear Communication **********-->
                <div id="qualification-clear-communication" class="qualification-item">
                    <span class="qualification-emoji">💎</span>
                    <!-- prettier-ignore -->
                    <div class="qualification-item-text">
                        Du kannst
                        <strong>glasklar kommunizieren</strong>.
                    </div>
                    <span
                        class="help-indicator fancy-tooltip"
                        title="Vor allem in Teilzeit ist es wichtig, dass du mit uns eindeutig kommunizieren kannst: per Skype, per Slack und per Telefon.">
                        ?
                    </span>
                </div>

                <!--********** Separate Office **********-->
                <div id="qualification-separate-office" class="qualification-item">
                    <span class="qualification-emoji">💼</span>
                    <!-- prettier-ignore -->
                    <div class="qualification-item-text">
                        Du hast ein abgetrenntes Büro
                        <strong>mit ruhiger Atmosphäre</strong>.
                    </div>
                    <span
                        class="help-indicator fancy-tooltip"
                        title="Die Orga für unser Team erfordert Fokus und Konzentration. Da sollte auch deine Umgebung mitspielen.">
                        ?
                    </span>
                </div>

                <!--============================================-->
                <!-- Soft Skills -->
                <!--============================================-->
                <div id="soft-skills">
                    <!--********** Languages **********-->
                    <div class="soft-skill-item">
                        <div class="soft-skill-icon-container">
                            <img id="german-flag" src="/assets/images/german-flag.svg" alt="Deutsche Flagge" />
                        </div>
                        <!-- prettier-ignore -->
                        <div>
                            Du sprichst
                            <strong>hervorragend Deutsch (C2)</strong>.
                        </div>
                    </div>

                    <!--********** Motivation **********-->
                    <div class="soft-skill-item">
                        <div class="soft-skill-icon-container">
                            <img src="/assets/images/sunglasses-emoji.png" alt="Coolness" />
                        </div>
                        <div>Du bist motiviert, hast Spaß an der Arbeit und bist ein cooles Team-Mitglied.</div>
                    </div>
                </div>
                <!--============================================-->
                <!-- END Soft Skills -->
                <!--============================================-->
            </div>
        </div>
    </div>
</section>
<!--============================================-->
<!-- END Qualifications -->
<!--============================================-->

<!--============================================-->
<!-- Tasks -->
<!--============================================-->
<section id="tasks-section">
    <div class="container">
        <div class="row mb-5">
            <div class="col-sm text-center">
                <h3 class="additional-headline">Was du bei uns tun wirst</h3>
                <h2>Deine Aufgaben</h2>
                In einem kleinen dynamischen Team braucht es keine starren Silos.
                <br class="d-none d-lg-block" />
                Freu dich stattdessen auf vielfältige spannende Aufgaben!
            </div>
        </div>

        <div class="row mb-5 justify-content-center">
            <!--********** Internal Accounting **********-->
            <div class="col-lg-4 mb-5">
                <div class="list-item-with-icon-container">
                    <div class="list-item-icon-container">
                        <i class="material-icons-outlined text-with-blue-gradient">account_balance</i>
                    </div>
                    <h4 class="list-item-title">Interne Orga | 80 %</h4>
                    <div class="label small-text text-center">
                        Reisekosten auszahlen, Eingangsrechnungen zahlen und
                        <br class="d-none d-lg-block" />
                        bei der Buchhaltung unterstützen (bei uns bereits hoch automatisiert 😉)
                    </div>
                </div>
            </div>

            <!--********** Product Management **********-->
            <div class="col-lg-4 mb-5">
                <div class="list-item-with-icon-container">
                    <div class="list-item-icon-container small-list-item-container">
                        <i class="material-icons-outlined text-with-blue-gradient">question_answer</i>
                    </div>
                    <h4 class="list-item-title">Abstimmung mit Steuerberater | 20 %</h4>
                    <div class="label small-text text-center">
                        Falls mal Rechnungen fehlen, fordere sie
                        <br class="d-none d-lg-block" />
                        an und bereite sie für den Steuerberater auf.
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--============================================-->
<!-- END Tasks -->
<!--============================================-->

<!--============================================-->
<!-- Location -->
<!--============================================-->
<section id="location-section" class="section-with-background">
    <div class="container">
        <div class="row align-items-center text-center text-lg-left">
            <div class="col-sm col-lg-8">
                <img class="mb-5" src="/assets/images/icons/place.png" alt="Place Icon" />
                <h3 class="additional-headline">Remote arbeiten & Team-Events</h3>
                <h2>Deine Location</h2>
                <p>Wir arbeiten remote im Süden Deutschlands: in und um Frankfurt, Stuttgart und München.</p>
                <p>
                    Am besten wohnst du irgendwo in
                    <strong>West-, Mittel- oder Süddeutschland</strong>, damit du
                    <br class="d-none d-lg-block" />
                    ohne weite Anreise bei manchen unserer Co-Workings (alle 3-4 Wochen für zwei Tage in
                    <br class="d-none d-lg-block" />
                    wechselnden Städten) dabei sein kannst. Bei Teilzeit musst du aber nicht allzu oft anreisen :)
                    <span
                        class="help-indicator fancy-tooltip"
                        title="Wir mieten uns zu den Co-Workings in moderne Co-Working Spaces in Frankfurt, Stuttgart, Würzburg, München und Ulm ein, je nachdem was dem Team gerade am besten passt und worauf wir am meisten Lust haben. Die Liste könnte sich auch um deinen Standort erweitern! Unser Mikrobüro in Burgau zwischen Augsburg und Ulm besuchen wir nur äußerst selten.">
                        ?
                    </span>
                </p>
            </div>

            <div class="col-sm col-lg-4">
                <img
                    src="/assets/images/office-photo-mark-and-robin.jpg"
                    alt="Mark und ein Junior Developer beim Co-Working in Stuttgart"
                    title="Mark und Junior Developer beim Co-Working in Stuttgart" />
            </div>
        </div>
    </div>
</section>
<!--============================================-->
<!-- END Location -->
<!--============================================-->

<!--============================================-->
<!-- Amenities -->
<!--============================================-->
<section id="amenities-section">
    <div class="container">
        <div class="row mb-5">
            <div class="col-sm text-center">
                <h3 class="additional-headline">Was dich erwartet</h3>
                <h2>Was ist sonst noch ganz nice?</h2>
                <p>Damit du dein Bestes geben kannst, schaffen wir dir den besten Rahmen.</p>
            </div>
        </div>

        <!--============================================-->
        <!-- List -->
        <!--============================================-->
        <div class="row">
            <!--********** IT Budget **********-->
            <div class="col-lg-4 mb-5">
                <div class="list-item-with-icon-container">
                    <div class="list-item-icon-container">
                        <img src="/assets/images/icons/devices.png" alt="Laptop & Smartphone Icon" />
                    </div>
                    <h4 class="list-item-title">1.500 € IT-Budget</h4>
                    <div class="label small-text text-center">
                        Für Laptop, Monitor, Headphones,
                        <br class="d-none d-lg-block" />
                        AirPods etc.
                    </div>
                </div>
            </div>

            <!--********** Direct Communication **********-->
            <div class="col-lg-4 mb-5">
                <div class="list-item-with-icon-container">
                    <div class="list-item-icon-container">
                        <img src="/assets/images/icons/record-voice-over.png" alt="Person Talking Icon" />
                    </div>
                    <h4 class="list-item-title">Startup-Spirit</h4>
                    <div class="label small-text text-center">
                        Wir sind schnell und bewegen viel.
                        <br class="d-none d-lg-block" />
                        No-Go-Area für starre Hierarchien.
                    </div>
                </div>
            </div>

            <!--********** Cool Customers **********-->
            <div class="col-lg-4 mb-5">
                <div class="list-item-with-icon-container">
                    <div class="list-item-icon-container">
                        <img src="/assets/images/icons/person-waving.png" alt="Winkender Mann Icon" />
                    </div>
                    <h4 class="list-item-title">Arbeite mit coolen Kunden</h4>
                    <div class="label small-text text-center">
                        Unsere Kunden sind Unternehmer durch
                        <br class="d-none d-lg-block" />
                        und durch, die ihr Handwerk 1A drauf haben.
                    </div>
                </div>
            </div>

            <!--********** Vacation Days **********-->
            <div class="col-lg-4 mb-5">
                <div class="list-item-with-icon-container">
                    <div class="list-item-icon-container small-list-item-container">
                        <i class="material-icons-outlined text-with-blue-gradient">beach_access</i>
                    </div>
                    <h4 class="list-item-title">3-6 Urlaubstage</h4>
                </div>
            </div>

            <!--********** Flexible Work Hours **********-->
            <div class="col-lg-4 mb-5">
                <div class="list-item-with-icon-container">
                    <div class="list-item-icon-container small-list-item-container">
                        <i class="material-icons-outlined text-with-blue-gradient">wifi</i>
                    </div>
                    <h4 class="list-item-title">Flexibles Arbeiten</h4>
                </div>
            </div>

            <!--********** Home Office **********-->
            <div class="col-lg-4 mb-5">
                <div class="list-item-with-icon-container">
                    <div class="list-item-icon-container small-list-item-container">
                        <i class="material-icons-outlined text-with-blue-gradient">home</i>
                    </div>
                    <h4 class="list-item-title">Home Office</h4>
                </div>
            </div>
        </div>
        <!--============================================-->
        <!-- END List -->
        <!--============================================-->
    </div>
</section>
<!--============================================-->
<!-- END Amenities -->
<!--============================================-->

<!--============================================-->
<!-- Professional and Personal Growth -->
<!--============================================-->
<section id="growth-section">
    <div id="growth-container" class="container">
        <div class="row">
            <div class="col">
                <!--********** Headline **********-->
                <h3 class="additional-headline">Dein Entwicklungsplan</h3>
                <h2 class="text-with-blue-gradient">Berufliche & persönliche Entwicklung</h2>

                <!--********** List **********-->
                <div id="growth-list">
                    <!--********** Life-long Learning **********-->
                    <div class="growth-list-item">
                        <i class="material-icons-outlined growth-list-item-icon text-with-blue-gradient">school</i>
                        <div>
                            Wir leben
                            <strong>life-long Learning</strong>
                            jeden Tag. Außerdem zahlen wir dir sinnvolle Kurse für deine Weiterbildung.
                        </div>
                    </div>

                    <!--********** Curriculum **********-->
                    <div class="growth-list-item">
                        <i class="material-icons-outlined growth-list-item-icon text-with-blue-gradient">menu_book</i>
                        <div>
                            <strong>Maßgeschneiderter Prozess-Lehrplan</strong>
                            in Abstimmung mit deinem Mentor.
                        </div>
                    </div>

                    <!--********** Onboarding **********-->
                    <div class="growth-list-item">
                        <i class="material-icons-outlined growth-list-item-icon text-with-blue-gradient">
                            rocket_launch
                        </i>
                        <div>
                            <strong>Onboarding:</strong>
                            Wir geben alles, damit du schnell auf Augenhöhe mit Kollegen & unserem Steuerberater
                            sprechen kannst.
                        </div>
                    </div>

                    <!--********** Salary **********-->
                    <div class="growth-list-item">
                        <i class="material-icons-outlined growth-list-item-icon text-with-blue-gradient">trending_up</i>
                        <div>
                            Wenn du viel für das Team leistest, wächst dein
                            <strong>Gehalt</strong>
                            mit dir.
                        </div>
                    </div>
                </div>

                <i id="trending-up-icon" class="material-icons-outlined">trending_up</i>
            </div>
        </div>
    </div>
</section>
<!--============================================-->
<!-- END Professional and Personal Growth -->
<!--============================================-->

<!--============================================-->
<!-- Application Process -->
<!--============================================-->
<section id="application-process-section" class="section-with-background">
    <div class="container">
        <div class="row mb-5">
            <div class="col-sm text-center">
                <h3 class="additional-headline">Was wir von dir brauchen</h3>
                <h2>Deine Bewerbung bei uns</h2>
                <p>Erzähl uns etwas von dir. Zeig uns deine Erfahrung und deine Motivation!</p>
            </div>
        </div>

        <div class="row justify-content-center mb-5">
            <div class="col-lg-4">
                <div class="checklist-item">
                    <img
                        class="checklist-item-icon"
                        src="/assets/images/icons/check-circle.png"
                        alt="Checklisten Icon" />
                    Lebenslauf
                </div>

                <!--                <div class="checklist-item">-->
                <!--                    <img class="checklist-item-icon" src="/assets/images/icons/check-circle.png" alt="Checklisten Icon">-->
                <!--                    Zeugnisse (Uni oder Arbeit)-->
                <!--                </div>-->

                <div class="checklist-item">
                    <img
                        class="checklist-item-icon"
                        src="/assets/images/icons/check-circle.png"
                        alt="Checklisten Icon" />
                    Deine Berufserfahrung
                </div>

                <div class="checklist-item">
                    <img
                        class="checklist-item-icon"
                        src="/assets/images/icons/check-circle.png"
                        alt="Checklisten Icon" />
                    Wann kannst du bei uns anfangen?
                </div>
            </div>
        </div>

        <!--============================================-->
        <!-- Application Process Mobile -->
        <!--============================================-->
        <div id="application-process-mobile" class="row justify-content-center mb-5">
            <div class="col-lg-4">
                <h4>So läuft der Bewerbungsprozess ab</h4>

                <div class="checklist-item">
                    <i class="application-process-icon material-icons-outlined">local_library</i>
                    Wir screenen deine Bewerbung
                </div>

                <div class="checklist-item">
                    <i class="application-process-icon material-icons-outlined">videocam</i>
                    2-3 Online-Interviews
                </div>

                <div class="checklist-item">
                    <i class="application-process-icon material-icons-outlined">local_cafe</i>
                    Persönliches Treffen
                </div>
            </div>
        </div>
        <!--============================================-->
        <!-- END Application Process Mobile -->
        <!--============================================-->

        <!--============================================-->
        <!-- Application Process Larger Screens -->
        <!--============================================-->
        <div id="application-process-larger-screens" class="row justify-content-center">
            <div class="col-lg-6">
                <h4 class="mb-3 text-center">So läuft der Bewerbungsprozess ab</h4>

                <!--********** Process Line (Horizontal Line) **********-->
                <div id="application-process-timeline">
                    <!--********** Application Screening **********-->
                    <div id="application-screening" class="application-process-larger-screens-item">
                        <div class="application-process-larger-screens-location-marker">
                            <i
                                class="application-process-larger-screens-icon material-icons-outlined text-with-blue-gradient">
                                local_library
                            </i>
                        </div>
                        <div class="application-process-larger-screens-item-label">Wir screenen deine Bewerbung</div>
                    </div>

                    <!--********** Zoom Interview **********-->
                    <div id="zoom-interview" class="application-process-larger-screens-item">
                        <div class="application-process-larger-screens-location-marker">
                            <i
                                class="application-process-larger-screens-icon material-icons-outlined text-with-blue-gradient">
                                videocam
                            </i>
                        </div>
                        <div class="application-process-larger-screens-item-label">
                            Zoom-
                            <br />
                            Interview
                        </div>
                    </div>

                    <!--********** Personal Meeting **********-->
                    <div id="personal-meeting" class="application-process-larger-screens-item">
                        <div class="application-process-larger-screens-location-marker">
                            <i
                                class="application-process-larger-screens-icon material-icons-outlined text-with-blue-gradient">
                                sports_bar
                            </i>
                        </div>
                        <div class="application-process-larger-screens-item-label">Persönliches Treffen</div>
                    </div>
                </div>
            </div>
        </div>
        <!--============================================-->
        <!-- END Application Process Larger Screens -->
        <!--============================================-->

        <div class="row justify-content-center">
            <div class="col-lg-10 text-center">
                <!--********** Picture of contact person **********-->
                <div id="contact-person-container">
                    <img src="/assets/images/people/andreas-sommer.jpg" alt="Andreas Schliefer" />
                    <div id="contact-person-name-column">
                        Dein Ansprechpartner
                        <h4>Andreas Schliefer</h4>
                        +49 8222 91 88 99 0
                        <div class="d-flex align-items-center">
                            <a
                                href="https://de.linkedin.com/in/andreas-schliefer-36000b150/"
                                class="mr-1"
                                target="_blank"
                                rel="noopener">
                                <img
                                    src="/assets/images/logos/linked-in-logo.png"
                                    alt="LinkedIn Logo"
                                    title="Öffne das Profil des Chief Finance Officers Andreas Schliefer" />
                            </a>
                        </div>
                    </div>
                </div>

                <a
                    class="exclamation-box-button button-solid"
                    href="mailto:bewerbung@autoixpert.de"
                    target="_blank"
                    rel="noopener">
                    Bewerbung starten
                </a>
            </div>
        </div>
    </div>
</section>
<!--============================================-->
<!-- END Application Process -->
<!--============================================-->
