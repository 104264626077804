<ax-webinar-hero
    [webinarDate]="webinarDate"
    [webinarTitleShort]="webinarTitleShort"
    [price]="price"
    [duration]="duration"></ax-webinar-hero>

<ax-webinar-agenda
    [maximumNumberOfParticipants]="maximumNumberOfParticipants"
    [webinarContents]="webinarContents"
    [webinarTitle]="webinarTitle">
    <p>
        Das Webinar für fortgeschrittene Sachverständige aus dem Bereich Kraftfahrzeugschaden und -bewertung, die den
        Einstieg in die Welt der technischen Gutachten bzw. der Beweissicherungsgutachten wagen wollen. Von der
        Auftragserteilung über den Aufbau eines solchen Gutachtens bis hin zu der Rechnungsstellung wird ein Überblick
        über die Möglichkeiten aber auch die Tücken eines solchen Gutachtens gegeben. Neben den Dos und Don’ts der
        technsichen Gutachten wird auch der Punkt Preisgestaltung und Kostenübernahme durch Rechtsschutzversicherer
        thematisiert.
    </p>
</ax-webinar-agenda>

<ax-webinar-target-group [targetGroupLevel]="targetGroupLevel">
    Für Kfz-Sachverständige, die in Beweissicherungs- und technische Gutachten einsteigen möchten.
</ax-webinar-target-group>

<ax-webinar-instructor-section [instructors]="instructors"></ax-webinar-instructor-section>

<ax-webinar-signup-section
    [webinarDate]="webinarDate"
    [duration]="duration"
    [price]="price"
    [webinarTitle]="webinarTitle"
    [webinarContents]="webinarContents"
    [instructor]="instructors[0].name"
    [eventOverbooked]="eventOverbooked"></ax-webinar-signup-section>

<ax-expert-webinar-faq></ax-expert-webinar-faq>
