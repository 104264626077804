import {Component} from '@angular/core';

@Component({
  selector: 'ax-youtube-channel-box',
  standalone: true,
  imports: [],
  templateUrl: './youtube-channel-box.component.html',
  styleUrl: './youtube-channel-box.component.scss'
})
export class YoutubeChannelBoxComponent {
    protected openYoutubeVideo(): void {
        // Open a new window
        // Create new <a> element because window.open opens a popup window in Chrome.
        Object.assign(document.createElement('a'), {
            target: '_blank',
            rel: 'noopener noreferrer',
            href: 'https://www.youtube.com/c/autoixpert',
        }).click();
    }
}
