import {Component, HostListener, Input} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";

@Component({
    selector: 'ax-hero-video-player',
    standalone: true,
    imports: [],
    templateUrl: './hero-video-player.component.html',
    styleUrl: './hero-video-player.component.scss',
})
export class HeroVideoPlayerComponent {
    @HostListener('window:keydown.Escape')
    keyDownPressed() {
        this.closeYoutubePlayer();
    }

    protected showYoutubePlayer: boolean = false;

    protected _safeVideoUrl: SafeResourceUrl = '';
    @Input() set videoUrl(value: string) {
        if (!value.includes('?')) {
            // Always enable autoplay, keep YouTube branding minimal and only related videos of our own channel
            // modestbranding=1 → Hides the YouTube logo
            // rel=0 → Prevents showing related videos from other channels (only from the same channel)
            // autoplay=1 → Starts playing the video automatically
            value += '?rel=0&showinfo=0&modestbranding=1&autoplay=1';
        }
        this._safeVideoUrl = this.sanitizeUrl(value);
    }

    @Input() dialogTitle: string = '';

    constructor(private sanitizer: DomSanitizer) {
    }

    private sanitizeUrl(url: string): SafeResourceUrl {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    openYoutubePlayer() {
        this.showYoutubePlayer = true;
    }

    closeYoutubePlayer() {
        this.showYoutubePlayer = false;
    }
}
