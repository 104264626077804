<ax-webinar-hero
    [webinarDate]="webinarDate"
    [webinarTitleShort]="webinarTitleShort"
    [price]="price"
    [duration]="duration"></ax-webinar-hero>

<ax-webinar-agenda
    [maximumNumberOfParticipants]="maximumNumberOfParticipants"
    [webinarContents]="webinarContents"
    [webinarTitle]="webinarTitle">
    <p>
        Das Webinar bietet fortgeschrittenen Sachverständigen einen ersten Einblick in die Begutachtung folierter
        Fahrzeuge. Neben einzelnen Werbebeschriftungen sind auch teil- und vollfolierte Fahrzeuge eine Thematik, die im
        Rahmen der klassischen Schadenbegutachtung zu Schwierigkeiten führen kann. Weiterhin gibt es einen Einblick in
        mögliche Schäden aufgrund fehlerhafter Folierung oder fehlerhafter Nachbearbeitung folierter Fahrzeuge.
    </p>
</ax-webinar-agenda>

<ax-webinar-target-group [targetGroupLevel]="targetGroupLevel">
    Für Kfz-Sachverständige, die Grundlagen zu Fahrzeugwerten erwerben oder vertiefen möchten.
</ax-webinar-target-group>

<ax-webinar-instructor-section [instructors]="instructors"></ax-webinar-instructor-section>

<ax-webinar-signup-section
    [webinarDate]="webinarDate"
    [duration]="duration"
    [price]="price"
    [webinarTitle]="webinarTitle"
    [webinarContents]="webinarContents"
    [instructor]="instructors[0].name"
    [eventOverbooked]="eventOverbooked"></ax-webinar-signup-section>

<ax-expert-webinar-faq></ax-expert-webinar-faq>
