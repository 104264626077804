@if (showYoutubePlayer) {
    <section id="hero-video-player-section" (click)="closeYoutubePlayer()">
        <svg id="close-video-player-icon" height="24" viewBox="0 0 24 24" width="24" (click)="closeYoutubePlayer()">
            <path
                d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                fill="white" />
        </svg>
        <h2>{{dialogTitle}}</h2>
        <iframe
            id="hero-youtube-video-player"
            width="80%"
            height="100%"
            [src]="_safeVideoUrl"
            allow="autoplay; encrypted-media"
            allowfullscreen></iframe>
    </section>
}
