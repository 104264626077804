<ax-webinar-hero
    [webinarDate]="webinarDate"
    [webinarTitleShort]="webinarTitleShort"
    [price]="price"
    [duration]="duration"></ax-webinar-hero>

<ax-webinar-agenda
    [maximumNumberOfParticipants]="maximumNumberOfParticipants"
    [webinarContents]="webinarContents"
    [webinarTitle]="webinarTitle">
    <p>
        Die Bewertung und Kalkulation im Nutzfahrzeugbereich unterscheiden sich elementar von der Vorgehensweise bei
        Standard-PKWs. Das Webinar vermittelt vor allem die Grundlagen der Nutzfahrzeugart LKW. Dabei werden Themen wie
        Fahrzeugidentifikation, Fahrzeugaufbau, Besonderheiten bei LKWs aber auch die gutachtenspezifischen Themen wie
        Wiederbeschaffungswerte, Restwerte, Schadenkalkulation und Minderwerte sowie Wertverbesserungen beleuchtet. Ein
        weiteres Thema ist die kurze Erläuterung der Fahrerhaus- und Rahmeninstandsetzungsmöglichkeiten. Das Webinar
        bietet den optimalen Einstieg in die Welt der LKW-Gutachten.
    </p>
</ax-webinar-agenda>

<ax-webinar-target-group [targetGroupLevel]="targetGroupLevel">
    Für Kfz-Sachverständige, die die Grundlagen zum Thema LKW-Gutachten kennenlernen möchten.
</ax-webinar-target-group>

<ax-webinar-instructor-section [instructors]="instructors"></ax-webinar-instructor-section>

<ax-webinar-signup-section
    [webinarDate]="webinarDate"
    [duration]="duration"
    [price]="price"
    [webinarTitle]="webinarTitle"
    [webinarContents]="webinarContents"
    [instructor]="instructors[0].name"
    [eventOverbooked]="eventOverbooked"></ax-webinar-signup-section>

<ax-expert-webinar-faq></ax-expert-webinar-faq>
