import {Component, inject} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {ExclamationBoxComponent} from '../../shared/components/exclamation-box/exclamation-box.component';
import {MatIconModule} from "@angular/material/icon";
import {YoutubeChannelBoxComponent} from "../../shared/components/youtube-channel-box/youtube-channel-box.component";

@Component({
    selector: 'ax-kfz-sachverstaendigen-software-fuer-mac',
    standalone: true,
    imports: [ExclamationBoxComponent, MatIconModule, YoutubeChannelBoxComponent],
    templateUrl: './kfz-sachverstaendigen-software-fuer-mac.component.html',
    styleUrl: './kfz-sachverstaendigen-software-fuer-mac.component.scss',
})
export class KfzSachverstaendigenSoftwareFuerMac {
    protected readonly titleService = inject(Title);
    protected readonly metaService = inject(Meta);

    constructor() {
        this.titleService.setTitle('Software für Kfz-Gutachter');
        this.metaService.addTag({
            name: 'description',
            content: 'Kfz-Sachverständigen-Software für Mac und iPad.',
        });
    }
}
