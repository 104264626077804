<section id="youtube-channel-section" class="feature-section">
    <div class="container">
        <div class="row">
            <div class="col-md">
                <div id="youtube-channel-container" class="exclamation-box-container" (click)="openYoutubeVideo()">
                    <!--============================================-->
                    <!-- Text -->
                    <!--============================================-->
                    <div id="youtube-channel-text-container">
                        <h3 class="additional-headline">autoiXpert auf YouTube</h3>
                        <h2 class="exclamation-box-heading">
                            Der Channel für
                            <br />
                            Gutachter & Sachverständige
                        </h2>
                        <div id="youtube-channel-buttons-container">
                            <!--********** Video Button **********-->
                            <div id="open-youtube-channel-button">
                                <img src="/assets/images/video-play-button.svg" alt="Play Icon" />
                                Zu den Videos
                            </div>

                            <!--********** YouTube Logo **********-->
                            <img
                                id="youtube-logo-in-channel-container"
                                src="/assets/images/logos/youtube-logo-light.svg"
                                alt="YouTube Logo" />
                        </div>
                    </div>
                    <!--============================================-->
                    <!-- END Text -->
                    <!--============================================-->

                    <!--============================================-->
                    <!-- Video Screenshots -->
                    <!--============================================-->
                    <div class="youtube-channel-thumbnails-container">
                        <div id="youtube-channel-thumbnails">
                            <img
                                src="/assets/images/screenshots/fahrzeugscheinscanner-video.jpg"
                                alt="Video-Thumbnail Fahrzeugscheinscanner für Kfz-Gutachter" />
                            <img
                                src="/assets/images/screenshots/start-als-sachverstaendiger-video.jpg"
                                alt="Video-Thumbnail Start als Kfz-Sachverständiger" />
                            <img
                                src="/assets/images/screenshots/restwertverteiler-video.jpg"
                                alt="Video-Thumbnail Restwertverteiler" />
                            <img
                                src="/assets/images/screenshots/fahrzeugbewertungen-video-thumbnail.png"
                                alt="Video-Thumbnail Fahrzeugbewertungen" />
                            <img
                                src="/assets/images/screenshots/auswertungen.jpg"
                                alt="Video-Thumbnail Auswertungen" />
                            <img
                                src="/assets/images/screenshots/lackschichtdicke-video-thumbnail.png"
                                alt="Video-Thumbnail Lackschichtdicke" />
                        </div>
                    </div>

                    <!--============================================-->
                    <!-- END Video Screenshots -->
                    <!--============================================-->
                </div>
            </div>
        </div>
    </div>
</section>
