<ax-webinar-hero
    [webinarDate]="webinarDate"
    [webinarTitleShort]="webinarTitleShort"
    [price]="price"
    [duration]="duration"></ax-webinar-hero>

<ax-webinar-agenda
    [maximumNumberOfParticipants]="maximumNumberOfParticipants"
    [webinarContents]="webinarContents"
    [webinarTitle]="webinarTitle">
    <p>
        Die Definition einzelner Werte im Sachverständigengutachten stellt die grundlegende Aufgabe des Gutachters
        dar. Das Webinar konkretisiert neben der Ermittlung von Wiederbeschaffungs- und Restwert auch die
        Besteuerung der Werte. Der merkantile oder technische Minderwert ist elementarer Bestandteil des Webinars.
        Neben der Frage, wann die Ermittlung des Minderwertes
        erforderlich ist wird neben den Berechnungsmodellen auch der Einfluss des Marktes thematisiert. Der Sachverständige
        erhält einen vollumfänglichen Überblick über die Definition der einzelnen Werte und Argumentationshilfen für die
        Durchsetzung seiner Wertfestlegungen.
    </p>
</ax-webinar-agenda>

<ax-webinar-target-group [targetGroupLevel]="targetGroupLevel">
    Für Kfz-Sachverständige, die Grundlagen zu Fahrzeugwerten erwerben oder vertiefen möchten.
</ax-webinar-target-group>

<ax-webinar-instructor-section [instructors]="instructors"></ax-webinar-instructor-section>

<ax-webinar-signup-section
    [webinarDate]="webinarDate"
    [duration]="duration"
    [price]="price"
    [webinarTitle]="webinarTitle"
    [webinarContents]="webinarContents"
    [instructor]="instructors[0].name"
    [eventOverbooked]="eventOverbooked"></ax-webinar-signup-section>

<ax-expert-webinar-faq></ax-expert-webinar-faq>
