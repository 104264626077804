<ax-webinar-hero
    [webinarDate]="webinarDate"
    [webinarTitleShort]="webinarTitleShort"
    [price]="price"
    [duration]="duration"></ax-webinar-hero>

<ax-webinar-agenda
    [maximumNumberOfParticipants]="maximumNumberOfParticipants"
    [webinarContents]="webinarContents"
    [webinarTitle]="webinarTitle">
    <!-- prettier-ignore -->
    <p>
        Die Erstellung eines Schadengutachtens an zwei- und dreirädrigen Fahrzeugen
        (<a href="https://de.wikipedia.org/wiki/EG-Fahrzeugklasse#Klasse_L">Fahrzeugklasse L</a>) stellt besondere
        Anforderungen an den Sachverständigen. Das Webinar stellt
        die
        <strong>Besonderheiten dieser Schadenfälle</strong>
        heraus und erläutert die grundlegenden Inhalte eines auf diese Fahrzeugart
        zugeschnittenen Gutachtens.
    </p>
</ax-webinar-agenda>

<ax-webinar-target-group [targetGroupLevel]="targetGroupLevel">
    Du solltest teilnehmen, wenn du in das Thema Erstellung von Gutachten und zwei- und dreirädrigen Fahrzeugen und
    deren Kalkulation einsteigen oder dein Grundlagenwissen auffrischen möchtest.
</ax-webinar-target-group>

<ax-webinar-instructor-section [instructors]="instructors"></ax-webinar-instructor-section>

<ax-webinar-signup-section
    [webinarDate]="webinarDate"
    [duration]="duration"
    [price]="price"
    [webinarTitle]="webinarTitle"
    [webinarContents]="webinarContents"
    [instructor]="instructors[0].name"
    [eventOverbooked]="eventOverbooked"></ax-webinar-signup-section>

<ax-expert-webinar-faq></ax-expert-webinar-faq>
